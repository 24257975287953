import React, {Component} from 'react';

import moment from 'moment';
import {Base64} from 'js-base64';
import {firestore, firebase, auth, firebaseLoaded} from "./firebaseConfig";

import {Redirect} from 'react-router-dom';
import {Route} from 'react-router-dom';
import SnackBar from 'material-ui/Snackbar';
import CircularProgress from 'material-ui/CircularProgress';

import ReactGA from 'react-ga';

import NavigationContainer from './navigation/NavigationContainer';
import VenuesPage from './venues/VenuesPage';
import AccountPage from './account/AccountPage';
import Frontpage from './frontpage/Frontpage';
import FirstLogin from './start/FirstLogin';
import OverviewPage from './overview/OverviewPage';
import EmployeeModule from './EmployeeModule/EmployeeModule';
import FooterModule from '../presentational/FooterModule';
import TOSPage from "./TOSPage/TOSPage";
// import LoginModule from "./LoginModule/LoginModule";
// import PageMargins from "../presentational/PageMargins";
import PrivacyPage from "./PrivacyPage/PrivacyPage";
import CookieBanner from "./CookieBanner/CookieBanner";
import SupportPage from "./SupportPage/SupportPage";

// Configure FirebaseUI.
export default class FirebaseContainer extends Component{
    constructor(props){
        super(props);

        // this.firestore = firestore;
        // this.uiConfig = {
        //     // Popup signin flow rather than redirect flow.
        //     signInFlow: 'redirect',
        //     // We will display Google and Facebook as auth providers.
        //     signInOptions: [
        //         firebase.auth.EmailAuthProvider.PROVIDER_ID,
        //         firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        //         firebase.auth.FacebookAuthProvider.PROVIDER_ID
        //     ],
        //     callbacks: {
        //         // Avoid redirects after sign-in.
        //         signInSuccess: () => false
        //     }
        // };

        this.otherAdminListeners = [];
        this.employeeListeners = [];

        // The component's Local state.
        this.state = {
            signedIn: false, // Local signed-in state.
            logoutRedirect: false,
            firstLoginRedirect: false,
            venuesCount: 0,
            firebase_loaded: false,
            uid: null,
            listeners: {
                venues: null,
                employees: null
            },
            venues: null,
            employees: null,
            last_stamp: {},
            disabledSnack: false,
            forceLoginOpen: false
        };
    }

    initFirebase = () => {
        if (!firebaseLoaded){console.warn("initFirebase - not loaded yet!");}
        this.firestore = firestore;

        auth.onAuthStateChanged(
            (user) => {
                // console.log("Auth state changed: ", !!user);
                if (!!user){
                    firebase.auth().currentUser.getIdToken(true).then(idToken => {
                        const payload = JSON.parse(Base64.decode(idToken.split('.')[1]));
                        this.employeeAccount = !!payload['employeeAccount'];

                        const logged_in = !!user && !this.employeeAccount;

                        // console.log("Is employee account -> ", this.employeeAccount);

                        const new_state = {
                            ...this.state,
                            signedIn: !!user && !this.employeeAccount,
                            employeeSignedIn: this.employeeAccount && !!user,
                            employeeAccount: this.employeeAccount,
                            firebase_loaded: true,
                            uid: logged_in ? user.uid : null
                        };

                        console.log('uid: ', logged_in ? user.uid : 'Not logged in');

                        // Create listeners for employee account
                        if (this.employeeAccount){
                            new_state.employeeAcc = {
                                venue_id: payload['connectedVenue'],
                                connectedUser: payload['connectedUser'],
                                employeeLoginId: user.uid
                            };

                            // console.log("empacc ",new_state.employeeAcc);

                            // Permission test
                            // console.log("Running permissions test 2", 'venue_id: ' ,new_state.employeeAcc.venue_id, 'connected user:', new_state.employeeAcc.connectedUser, 'uid', user.uid);
                            // this.firestore.doc(`permissionstest/test_2/${new_state.employeeAcc.venue_id}/${new_state.employeeAcc.connectedUser}`).get().then(snap => {
                            //     console.log("permissions test 2 success", snap.data());
                            // });

                            // users
                            console.log("Creating employees listener for employee user");
                            const ul = this.firestore.collection(`private_data/${new_state.employeeAcc.connectedUser}/venues/${new_state.employeeAcc.venue_id}/employees`).onSnapshot(snap => {
                                console.log("Loading users snap for employee user...");
                                const emps = [];
                                snap.forEach(emp => {emps.push({...emp.data(), employee_id: emp.id})});
                                this.setState(pendingState => ({
                                    ...pendingState,
                                    employees: emps
                                }));
                            });


                            // User listener to check for disabled-ness
                            const userlistener = this.firestore.doc(`private_data/${new_state.employeeAcc.connectedUser}`).onSnapshot(snap => {
                                const fs_data = Object.assign({}, snap.data());
                                const freeTrial_until_moment = moment.unix(fs_data.freeTrial_until);
                                const disableDate = moment.unix(fs_data.paid_until);
                                disableDate.add(14, 'days');
                                const unsubscribeDisableDate = moment.unix(fs_data.paid_until);
                                const now_moment = moment();



                                let disabled = false;

                                // Trial ran out
                                if (!fs_data.hasCreatedFirstInvoice && now_moment.isAfter(freeTrial_until_moment)){
                                    disabled = true;
                                }

                                // Missing payments
                                if (fs_data.hasCreatedFirstInvoice && now_moment.isAfter(disableDate) && !fs_data.is_cancelled){
                                    disabled = true;
                                }

                                // Cancelled subscription
                                if (fs_data.is_cancelled){
                                    if (now_moment.isAfter(unsubscribeDisableDate)){
                                        disabled = true;
                                    }
                                }

                                this.setState(pendingState => ({
                                    ...pendingState,
                                    employeeDisabled: disabled
                                }));
                            });

                            this.otherAdminListeners.push(this.venuelistener);
                            this.otherAdminListeners.push(userlistener);

                            this.employeeListeners.push(ul);
                        }

                        // Create listeners for admin account
                        if (logged_in){
                            // Check if new user -> if new fire conversion
                            if (user.metadata.creationTime === user.metadata.lastSignInTime){
                                this.fireAnalyticsEvent('ga_signup');
                            }

                            new_state.userInfo = {
                                displayName: user.displayName,
                                photoURL: user.photoURL
                            };

                            // Venues listener
                            this.venuelistener = this.firestore.collection('private_data').doc(new_state.uid).collection('venues').onSnapshot(snap => {
                                const new_state = Object.assign({}, this.state);

                                if (snap.docs.length === 0){
                                    console.log("Detected 0 venues!");
                                    new_state.firstLoginRedirect = true;
                                    new_state.venuesCount = snap.docs.length;
                                }else{
                                    new_state.firstLoginRedirect = false;
                                    new_state.venuesCount = snap.docs.length;
                                }

                                const new_venues = [];
                                snap.forEach(point => {
                                    new_venues.push({...point.data(), venue_id: point.id});
                                });

                                new_state.venues = new_venues;

                                this.setState(new_state);
                            });

                            // User listener
                            const userlistener = this.firestore.doc(`private_data/${new_state.uid}`).onSnapshot(snap => {

                                const fs_data = Object.assign({}, snap.data());
                                const freeTrial_until_moment = moment.unix(fs_data.freeTrial_until);
                                const disableDate = moment.unix(fs_data.paid_until).add(14, 'days');
                                const unsubscribeDisableDate = moment.unix(fs_data.paid_until);
                                const now_moment = moment();

                                let disabled = false;

                                // Trial ran out
                                if (!fs_data.hasCreatedFirstInvoice && now_moment.isAfter(freeTrial_until_moment)){
                                    fs_data.trialExpiredNotice = true;
                                    disabled = true;
                                }else{
                                    fs_data.trialExpiredNotice = false;
                                }

                                // Trial has expired bool
                                if (fs_data.paid_until && now_moment.isAfter(freeTrial_until_moment) && this.state.venues && this.state.venues.length > 0){
                                    fs_data.trialExpired = true;
                                }else{
                                    fs_data.trialExpired = false;
                                }

                                // Missing payments
                                if (fs_data.paid_until && fs_data.hasCreatedFirstInvoice && now_moment.isAfter(disableDate) && !fs_data.is_cancelled){
                                    fs_data.disabledNotice = true;
                                    disabled = true;
                                }else{
                                    fs_data.disabledNotice = false;
                                }

                                // Cancelled subscription
                                if (fs_data.paid_until && fs_data.is_cancelled){
                                    if (now_moment.isAfter(unsubscribeDisableDate)){
                                        fs_data.unsubEffectiveNotice = true;
                                        fs_data.unsubImminentNotice = false;
                                        disabled = true;
                                    }else{
                                        fs_data.unsubEffectiveNotice = false;
                                        fs_data.unsubImminentNotice = true;
                                    }
                                }

                                fs_data.disabled = disabled;
                                fs_data.uid = new_state.uid;

                                this.setState(pendingState => ({
                                    ...pendingState,
                                    fsUser: fs_data
                                }));
                            });

                            this.otherAdminListeners.push(this.venuelistener);
                            this.otherAdminListeners.push(userlistener);
                        }else if(this.employeeAccount){
                            // Employee account firebase logged in
                        }

                        this.setState(new_state);
                    });
                }else{
                    // Remove employee listeners
                    for (let listener of this.employeeListeners){
                        listener();
                    }

                    // Remove admin listeners
                    for (let listener of this.otherAdminListeners){
                        listener();
                    }

                    this.setState(pendingState => {
                        return {
                            ...pendingState,
                            signedIn: false,
                            employeeSignedIn: false,
                            employeeAccount: false,
                            firebase_loaded: true,
                            uid: null,
                            venues: null,
                            employees: null
                        }
                    })
                }
            }
        );
    };

    // Listen to the Firebase Auth state and set the local state.
    componentDidMount(){
        // Load firebase if the modules are ready - otherwise create callback to load when ready
        if (firebaseLoaded){
            this.initFirebase();
        } else {
            window.firebaseLoadedCallbacks.push(this.initFirebase);
        }

        // Google analytics
        ReactGA.initialize('UA-116855205-1');  // second argument can be {debug: true} for debug data
    }

    componentWillUnmount(){
        if (this.venuelistener){
            this.venuelistener();
        }

        // Remove all employeelisteners
        if (this.employeeListeners){
            for (let listener of this.employeeListeners){
                listener();
            }
        }

        // Remove callback for firebase loaded
        window.firebaseLoadedCallbacks = window.firebaseLoadedCallbacks.filter(f => f !== this.initFirebase);
    }

    // Redirect to front page
    logoutRedirect = () => {
        this.setState(pendingState => ({
            ...pendingState,
            logoutRedirect: true
        }));
    };

    logoutRedirectComplete(){
        this.setState(pendingState => ({
            ...pendingState,
            logoutRedirect: false
        }));
    }

    firstLoginRedirectComplete = () => {
        this.setState(pendingState => ({
            ...pendingState,
            firstLoginRedirect: false
        }));
    };

    // *******************************
    // ***** firestore functions *****
    // *******************************

    checkLogin = (function_name) => {
        if (!this.state.signedIn){throw new Error("Cannot "+function_name+" when not logged in");}
    };

    addVenue = (venue_name, venue_nr, venue_addr, venue_zip, venue_city, venue_boss) => {
        this.checkLogin('addVenue');

        return this.firestore.collection('private_data').doc(this.state.uid).collection('venues').add({
            venue_name: venue_name,
            venue_nr: venue_nr,
            venue_addr: venue_addr,
            venue_zip: venue_zip,
            venue_city: venue_city,
            venue_boss: venue_boss
        }).then(res => {
            console.log("addVenue success");
            console.log(res);
            return res.id;
        }).catch(err => {
            console.error(err);
        });
    };

    editVenue = (venue_id, venue_name, venue_nr, venue_addr, venue_zip, venue_city, venue_boss) => {
        return this.firestore.doc(`private_data/${this.state.uid}/venues/${venue_id}`).set({
            venue_name: venue_name,
            venue_nr: venue_nr,
            venue_addr: venue_addr,
            venue_zip: venue_zip,
            venue_city: venue_city,
            venue_boss: venue_boss
        }, {merge: true});
    };

    addStart = (venue_name, venue_nr, venue_addr, venue_zip, venue_city, venue_boss, employee_array) => {
        console.log("Starting addStart", venue_name, venue_nr, employee_array);

        return this.addVenue(
            venue_name,
            venue_nr,
            venue_addr,
            venue_zip,
            venue_city,
            venue_boss).then(venue_id => {
                if (!venue_id){throw new Error('Cannot add employees when venue_id is null!');}
                this.addEmployees(venue_id, employee_array);
        }).then(res => {
            console.log("addStart success!");
            return res;
        }).catch(err => {
            console.error("Could not complete acion", err);
        });
    };

    addEmployees = (venue_id, employee_array) => {
        if (employee_array.length > 300){
            console.error("Kan ikke legge til mer enn 300 om gangen.");
        }

        const root_promise = [];

        for (let emp of employee_array){
            const np = this.firestore.collection('private_data').doc(this.state.uid).collection('venues').doc(venue_id).collection('employees').add({
                name: emp.name,
                username: emp.username,
                ssn: emp.ssn,
                password: emp.password,
                deleted: false
            });
            root_promise.push(np);
        }

        return Promise.all(root_promise).then(res => {
            console.log("New employees added!");
            return res;
        })
    };

    listenForEmployees = (venue_id) => {
        return this.firestore.collection(`private_data/${this.state.uid}/venues/${venue_id}/employees`).onSnapshot(snap => {
            const emps = [];
            snap.forEach(doc => {
                emps.push({...doc.data(), employee_id: doc.id});
            });

            this.setState(pendingState => ({
                ...pendingState,
                employees: emps
            }));
        });
    };

    listenForLastStamp = (venue_id, employee_id) => {
        // console.log(this.state.uid ? this.state.uid : this.state.employeeAcc.connectedUser, venue_id, employee_id);
        return this.firestore.collection(`private_data/${this.state.uid ? this.state.uid : this.state.employeeAcc.connectedUser}/venues/${venue_id}/employees/${employee_id}/stamp_log`).orderBy('ts', 'desc').limit(1).onSnapshot(snap => {
            const new_data = Object.assign({}, this.state.last_stamp);

            new_data[employee_id] = null;

            snap.forEach(doc => {
                new_data[employee_id] = {...doc.data(), log_id: doc.id};
            });

            this.setState(pendingState => ({
                ...pendingState,
                last_stamp: new_data
            }));
        });
    };

    addStamp = (venue_id, employee_id, stamp_out) => {
        console.log("Adding stamp!");
        const ref = this.firestore.collection(`private_data/${this.state.uid ? this.state.uid : this.state.employeeAcc.connectedUser}/venues/${venue_id}/employees/${employee_id}/stamp_log`).doc();
        const id = ref.id;
        const prm = ref.set({
            out: stamp_out
        });

        return {
            prm: prm,
            id: id
        }
    };

    applyEmployeeChanges = (venue_id, employee_id, employee_data) => {
        return this.firestore.doc(`private_data/${this.state.uid}/venues/${venue_id}/employees/${employee_id}`).set(employee_data);
    };

    forceLoginOpen = () => {
        this.setState(pendingState => ({
            ...pendingState,
            forceLoginOpen: true
        }));
    };

    forceLoginOpenFinished = () => {
        this.setState(pendingState => ({
            ...pendingState,
            forceLoginOpen: false
        }));
    };

    exportRequest = (venue_id, from_ts, to_ts) => {
        // Get all data inbetween ranges

        console.log("venue export", venue_id, from_ts, to_ts);

        // Create list of employee ids
        const emp_ids = [];
        for (let emp of this.state.employees){
            emp_ids.push(emp.employee_id);
        }

        const promises = [];
        const results = {};
        const employee_versions = {};
        for (let eid of emp_ids){
            // Get stamps
            results[eid] = [];
            promises.push(this.firestore.collection(`private_data/${this.state.uid}/venues/${venue_id}/employees/${eid}/stamp_log`).orderBy('ts').where('ts', '>=', from_ts).where('ts', '<=', to_ts).get().then(res => {
                res.forEach(point => {
                    results[eid].push({...point.data(), id: point.id});
                })
            }));

            // Get employee versions
            employee_versions[eid] = [];
            promises.push(this.firestore.collection(`private_data/${this.state.uid}/venues/${venue_id}/employees/${eid}/version_log`).orderBy('version_ended_ts', 'desc').get().then(res => {
                res.forEach(point => {
                    employee_versions[eid].push(point.data());
                });
            }));
        }

        return Promise.all(promises).then(() => {
            return {stamps: results, employee_versions: employee_versions};
        });
    };

    setEmployeeLinkActive = (venue_id, active) => {
        return this.firestore.doc(`private_data/${this.state.uid}/venues/${venue_id}`).set({
            employeeLinkActive: active
        }, {merge: true});
    };

    addPurchaseAttempt = (stripeToken) => {
        const ref = this.firestore.collection(`private_data/${this.state.uid}/purchase_attempts`).doc();
        return ref.set({
            stripeToken: stripeToken
        }).then(() => {
            return ref;
        }).catch(err => {
            console.error("addPurchaseAttempt: ", err);
            return 'ERROR';
        });
    };

    addUpdateCardAttempt = (stripeToken) => {
        const ref = this.firestore.collection(`private_data/${this.state.uid}/update_card_attempts`).doc();
        return ref.set({
            stripeToken: stripeToken
        }).then(() => {
            return ref;
        }).catch(err => {
            console.error("addUpdateCardAttempt: ", err);
            return 'ERROR';
        });
    };

    addUnsubAttempt = () => {
        const ref = this.firestore.collection(`private_data/${this.state.uid}/cancel_attempts`).doc();

        return ref.set({
            ts: moment().valueOf()
        }).then(() => {
            return ref;
        }).catch(err => {
            console.error("addUnsubAttempt: ", err);
            return 'ERROR';
        });
    };

    openDisabledSnack = () => {
        this.setState({
            ...this.state,
            disabledSnack: true
        });
    };

    handleDisableSnackClose = () => {
        this.setState({
            ...this.state,
            disabledSnack: false
        });
    };

    addAttemptStatusListener = (attempt_ref, callback) => {
        const listener = attempt_ref.onSnapshot(snap => {

            const res = snap.data();
            if (res){
                listener();
                callback(res.s)
            }
        });

        return listener;
    };

    fireTracking = (page) => {
        ReactGA.pageview(page)
    };

    fireAnalyticsEvent = (event_name) => {
        // console.log("Fire conversion");
        ReactGA.event({
            action: event_name,
            category: 'main_events'
        });
    };

    render() {
        // Logout redirect
        if (this.state.logoutRedirect){
            return <LogoutRedirectComponent logoutRedirectComplete={() => {this.logoutRedirectComplete();}}/>
        }

        // First login redirect
        if (this.state.firstLoginRedirect){
            return <FirstLoginRedirectComponent firstLoginRedirectComplete={this.firstLoginRedirectComplete}/>
        }

        // Login
        let login_page_content = null;
        if(this.state.signedIn){
            if (this.state.venues && Object.keys(this.state.venues).length === 1){
                login_page_content = () => <Redirect to={'/virksomheter/' + this.state.venues[0].venue_id}/>
            }else{
                login_page_content = () => <Redirect to='/virksomheter'/>;
            }
        }else{
            login_page_content = () => <Redirect to={'/'}/>;
        }

        // Other pages
        let page_venues = null;
        let page_account = null;
        let page_start = null;
        let page_overview = null;
        if (this.state.signedIn){
            page_venues = this.state.firebase_loaded ? () => <VenuesPage venues={this.state.venues}
                                            addVenue={this.addVenue}
                                            editVenue={this.editVenue}
                                            onEnter={this.fireTracking}
            /> : <FullScreenLoading/>;
            page_account = this.state.firebase_loaded ? () => <AccountPage addPurchaseAttempt={this.addPurchaseAttempt}
                                              addUnsubAttempt={this.addUnsubAttempt}
                                              addUpdateCardAttempt={this.addUpdateCardAttempt}
                                              fsUser={this.state.fsUser}
                                              addAttemptStatusListener={this.addAttemptStatusListener}
                                              venues={this.state.venues}
                                              onEnter={this.fireTracking}
                                              fireAnalyticsEvent={this.fireAnalyticsEvent}
                                              firestore={this.firestore}
            /> : <FullScreenLoading/>;
            page_start = this.state.firebase_loaded ? () => <FirstLogin firestoreAddStart={this.addStart} onEnter={this.fireTracking}/> : <FullScreenLoading/>;
            page_overview = this.state.firebase_loaded ? (props) => <OverviewPage {...props}
                                                     listenForEmployees={this.listenForEmployees}
                                                     employees={this.state.employees}
                                                     last_stamp={this.state.last_stamp}
                                                     addStamp={this.addStamp}
                                                     listenForLastStamp={this.listenForLastStamp}
                                                     key='pageKey_overview'
                                                     addEmployees={this.addEmployees}
                                                     applyEmployeeChanges={this.applyEmployeeChanges}
                                                     exportRequest={this.exportRequest}
                                                     setEmployeeLinkActive={this.setEmployeeLinkActive}
                                                     venues={this.state.venues}
                                                     fsUser={this.state.fsUser}
                                                     openDisabledSnack={this.openDisabledSnack}
                                                     onEnter={this.fireTracking}
            /> : <FullScreenLoading/>;
        } else if (this.state.firebase_loaded){
            page_venues = () => <Redirect to='/login' />;
            page_account = () => <Redirect to='/login' />;
            page_start = () => <Redirect to='/login' />;
            page_overview = () => <Redirect to='/login' />;
        } else {
            page_venues = () => <FullScreenLoading/>;
            page_account = () => <FullScreenLoading/>;
            page_start = () => <FullScreenLoading/>;
            page_overview = () => <FullScreenLoading/>;
        }

        return (
            <div>
                <NavigationContainer
                    logged_in={this.state.signedIn}
                    firebase_loaded={this.state.firebase_loaded}
                    firebase_ref={this.fb}
                    logoutRedirect={this.logoutRedirect}
                    employeeAccount={this.state.employeeAccount}
                    venues={this.state.venues}
                    userInfo={this.state.userInfo}
                    fsUser={this.state.fsUser}
                    forceLoginOpenValue={this.state.forceLoginOpen}
                    forceLoginOpenFinished={this.forceLoginOpenFinished}
                />
                <Route exact path='/virksomheter' render={page_venues}/>
                <Route path='/virksomheter/:venue_id' render={page_overview}/>
                <Route exact path='/login' render={login_page_content}/>
                <Route exact path='/konto' render={page_account}/>
                <Route exact path='/start' render={page_start}/>
                <Route exact path='/tos' render={() => <TOSPage onEnter={this.fireTracking} />}/>
                <Route exact path='/support' render={() => <SupportPage onEnter={this.fireTracking}/>}/>
                <Route exact path='/personvern' render={() => <PrivacyPage onEnter={this.fireTracking}/>}/>
                <Route path='/ansattlink/:ansatt_uid' render={this.state.firebase_loaded ? props => <EmployeeModule {...props}
                                                                                       adminSignedIn={this.state.signedIn}
                                                                                       employeeAccount={this.state.employeeAccount}
                                                                                       employeeAcc={this.state.employeeAcc}
                                                                                       employees={this.state.employees}
                                                                                       listenForLastStamp={this.listenForLastStamp}
                                                                                       last_stamp={this.state.last_stamp}
                                                                                       addStamp={this.addStamp}
                                                                                       firebase_loaded={this.state.firebase_loaded}
                                                                                       logoutRedirect={this.logoutRedirect}
                                                                                       employeeDisabled={this.state.employeeDisabled}
                                                                                       openDisabledSnack={this.openDisabledSnack}
                                                                                       onEnter={this.fireTracking}
                /> : () => <FullScreenLoading/>}/>
                <Route exact path='/' render={props => <Frontpage forceLoginOpen={this.forceLoginOpen} onEnter={this.fireTracking}/>}/>
                <FooterModule/>
                <SnackBar
                    open={this.state.disabledSnack}
                    message='Systemet er sperret. Legg inn en gyldig betalingsløsning for å gjenåpne.'
                    autoHideDuration={4000}
                    onRequestClose={this.handleDisableSnackClose}
                />
                <CookieBanner/>
            </div>
        );
    }
}


class LogoutRedirectComponent extends Component{
    componentDidMount(){
        this.props.logoutRedirectComplete();
    }

    render(){
        return <Redirect to='/'/>;
    }
}

class FirstLoginRedirectComponent extends Component{
    componentDidMount(){
        this.props.firstLoginRedirectComplete();
    }

    render(){
        return <Redirect to='/start'/>
    }
}

class FullScreenLoading extends Component{
    render(){
        return(
            <div style={{width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <div>
                    <CircularProgress size={100} thickness={9} color={'rgb(63, 81, 181)'}/>
                </div>
            </div>
        )
    }
}