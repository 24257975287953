// import firebase from 'firebase/app';
// import 'firebase/functions';
// import 'firebase/auth';
// import 'firebase/firestore';

const p1 = import('firebase/app');
const p2 = import('firebase/functions');
const p3 = import('firebase/auth');
const p4 = import('firebase/firestore');

let fbInstance = null;
let firebase = null;
let functions = null;
let firestore = null;
let auth = null;
let firebaseLoaded = false;
window.firebaseLoadedCallbacks = [];

Promise.all([p1, p2, p3, p4]).then(res => {
    // Init firebase
    const config = {
        apiKey: "AIzaSyDyftlO3yIPfx1FzTVoygF1CBeTL-xtl2w",
        authDomain: "personalliste-da8a6.firebaseapp.com",
        databaseURL: "https://personalliste-da8a6.firebaseio.com",
        projectId: "personalliste-da8a6",
        storageBucket: "personalliste-da8a6.appspot.com",
        messagingSenderId: "255152303979"
    };

    firebase = res[0];
    fbInstance = firebase.initializeApp(config);
    functions = fbInstance.functions();
    firestore = fbInstance.firestore();
    auth = fbInstance.auth();
    firebaseLoaded = true;

    if (Array.isArray(window.firebaseLoadedCallbacks)){
        window.firebaseLoadedCallbacks.forEach(f => {f();});
    }
});



// const fbInstance = firebase.initializeApp(config);
// const functions = fbInstance.functions();
// const firestore = fbInstance.firestore();
// const auth = fbInstance.auth();

// moment

export {firebase, fbInstance, firestore, auth, functions, firebaseLoaded}