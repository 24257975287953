import React from 'react';
import {firebase} from '../firebaseConfig';
import axios from 'axios';

import PageMargins from '../../presentational/PageMargins';
import StampModule from '../StampModule/StampModule';

import './EmployeeModule.css';

// Material UI
import Dialog from 'material-ui/Dialog';
import TextField from 'material-ui/TextField';
import FlatButton from 'material-ui/FlatButton';
import CircularProgress from 'material-ui/CircularProgress';
import RaisedButton from 'material-ui/RaisedButton';

export default class EmployeeModule extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            show_pin: false,
            employeeSigningFlowStage: 0,
            pinValue: "",
            loadingLogin: true
        }
    }

    componentWillMount(){
        this.props.onEnter('employeeModule');
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.employeeAccount){
            this.setState({
                ...this.state,
                employeeSigningFlowStage: 10
            });
        }
    }

    componentDidMount(){
        console.log("EmployeeModule did mount!");

        // If admin is logged in -> logout
        if (this.props.employeeSignedIn){
            this.setState({
                ...this.state,
                employeeSigningFlowStage: 10
            });
        }else if (this.props.adminSignedIn){
            firebase.auth().signOut().then(() => {
                console.log("Signing out!");
                this.setState({
                    ...this.state,
                    employeeSigningFlowStage: 1
                });
            });
        }else{
            this.setState({
                ...this.state,
                employeeSigningFlowStage: 1
            })
        }

        setTimeout(() => {
            this.setState({
                ...this.state,
                loadingLogin: false
            });
        }, 2500);
    }

    handlePinSubmit = () => {
        this.setState({
            ...this.state,
            employeeSigningFlowStage: 3
        });

        axios.post('https://us-central1-personalliste-da8a6.cloudfunctions.net/requestEmployeeToken', {
            employee_login_id: this.props.match.params.ansatt_uid,
            employee_login_pin: parseInt(this.state.pinValue, 10)
        }).then(res => {
            console.log(res);
            if (res.data === 'CredentialsError'){
                this.setState({
                    ...this.state,
                    employeeSigningFlowStage: -1,
                    error_message: "Feil link eller pin"
                });
            }else{
                console.log(res.data);
                this.employeeToken = res.data;
                this.setState({
                    employeeSigningFlowStage: 4
                });

                // Login -> firebase
                firebase.auth().signInWithCustomToken(this.employeeToken).catch(err => {
                    // Handle Errors here.
                    const errorCode = err.code;
                    const errorMessage = err.message;
                    console.log("Firebase login error", errorCode, errorMessage);
                });
            }
        }).catch(err => {
            console.log("Error logging in as employee: ", err);
        });
    };

    handlePinChange = (event) => {
        this.setState({
            ...this.state,
            pinValue: event.target.value
        })
    };

    handleLogOut = () => {
        firebase.auth().signOut();
        this.setState({
            show_pin: false,
            employeeSigningFlowStage: 0,
            pinValue: "",
            loadingLogin: true,
        });
        this.props.logoutRedirect();
    };

    render(){
        // Stamp module
        let stamp_module = null;
        if (this.props.employeeAccount){
            stamp_module = <StampModule employees={this.props.employees}
                                        last_stamp={this.props.last_stamp}
                                        addStamp={this.props.addStamp}
                                        venue_id={this.props.employeeAcc.venue_id}
                                        listenForLastStamp={this.props.listenForLastStamp}
                                        employeeDisabled={this.props.employeeDisabled}
                                        openDisabledSnack={this.openDisabledSnack}
            />
        }

        let loading = null;
        if (this.state.employeeSigningFlowStage === 3 || this.state.employeeSigningFlowStage === 4){
            loading = (
                <div className='loadingWrapper'>
                    <div className='loadingInner'>
                        <CircularProgress/>
                    </div>
                </div>);
        }

        return(
            <PageMargins>
                <div style={{position: 'relative'}}>
                    <div>
                        <h1>Personalliste {this.state.loadingLogin ? <CircularProgress size={30}/> : null}</h1>
                    </div>
                        {loading}
                    <div>
                        {stamp_module}
                    </div>
                    <div className='logoutSpacer'/>
                    {this.state.employeeSigningFlowStage === 10 ? <div className='logoutWrapper'><RaisedButton label='Logg ut' backgroundColor='#F44336' onClick={this.handleLogOut}/></div> : null}
                </div>
                <Dialog open={(this.state.employeeSigningFlowStage === 1 || this.state.employeeSigningFlowStage === -1) && this.props.firebase_loaded && !this.state.loadingLogin}
                        modal={true}
                        actions={<FlatButton onClick={this.handlePinSubmit} label='OK'/>}
                >
                    <h2>Skriv inn pin-kode</h2>
                    <p>Pin-koden ligger på administratorsiden.</p>
                    {this.state.employeeSigningFlowStage === -1 ? <p className="signInError">Kunne ikke logge inn. Dobbeltsjekk at du bruker riktig link og pin-kode. Hvis det fremdeles ikke fungerer ta kontakt med support.</p> : null}
                    <TextField value={this.state.pinValue}
                               onChange={this.handlePinChange}
                               name='pinField'
                    />
                </Dialog>
            </PageMargins>
        );
    }
}