import React from 'react';
import moment from 'moment';

import './ResultsTable.css';

import {
    Table,
    TableBody,
    TableHeader,
    TableHeaderColumn,
    TableRow,
    TableRowColumn,
} from 'material-ui/Table';

export default class ResultsTable extends React.Component{
    render(){
        const view = [];
        let results_print = [];

        // Create employee dict
        const employee_dict = {};
        for (let e of this.props.employees){
            employee_dict[e.employee_id] = e;
        }

        if(this.props.viewMode === 'raw'){
            console.log("Starting raw mode");


            for (let key of Object.keys(this.props.results)){
                for (let entry of this.props.results[key]){
                    results_print.push({
                        e: key,
                        ts: entry.ts,
                        out: entry.out,
                        id: entry.id,
                        ssn: entry.ssn || null,
                        name: entry.name || null
                    });
                }
            }

            results_print.sort((a, b) => {
                return a.ts - b.ts;
            });

            for (let entry of results_print){
                // Get correct version
                let correct_version = employee_dict[entry.e];
                for (let version of this.props.employee_versions[entry.e]){
                    if (version.version_ended_ts > entry.ts){
                        correct_version = version;
                    }else{
                        break;
                    }
                }

                view.push(
                    <TableRow key={entry.id}>
                        <TableRowColumn>{moment(entry.ts).format("DD.MM.YYYY HH:mm")}</TableRowColumn>
                        <TableRowColumn>{entry.ssn || correct_version.ssn}</TableRowColumn>
                        <TableRowColumn>{entry.name || correct_version.name}</TableRowColumn>
                        <TableRowColumn>{entry.out ? <span className='entryOut'>Ut</span> : <span className='entryIn'>Inn</span>}</TableRowColumn>
                    </TableRow>
                );
            }
        }

        return(
            <div className='resultsTableWrapper'>
                <Table selectable={false}>
                    <TableHeader displaySelectAll={false}
                                 adjustForCheckbox={false}
                                 enableSelectAll={false}
                    >
                        <TableRow>
                            <TableHeaderColumn>Tidspunkt</TableHeaderColumn>
                            <TableHeaderColumn>Personnummer/kode</TableHeaderColumn>
                            <TableHeaderColumn>Navn</TableHeaderColumn>
                            <TableHeaderColumn>Stemplet inn/ut</TableHeaderColumn>
                        </TableRow>
                    </TableHeader>
                    <TableBody displayRowCheckbox={false}>
                        {view}
                    </TableBody>
                </Table>
            </div>
        );
    }
}