import React from 'react';

import StampForm from '../StampForm/StampForm';

export default class StampModule extends React.Component{
    render(){
        let emps = [];
        if (this.props.employees){
            for (let emp of this.props.employees){
                if (emp.deleted){continue;}

                emps.push(
                    <StampForm employee={emp}
                               key={emp.employee_id}
                               last_stamp={this.props.last_stamp[emp.employee_id] || null}
                               addStamp={this.props.addStamp}
                               venue_id={this.props.venue_id}
                               listenForLastStamp={this.props.listenForLastStamp}
                               fsUser={this.props.fsUser}
                               openDisabledSnack={this.props.openDisabledSnack}
                               employeeDisabled={this.props.employeeDisabled}
                    />
                );
            }
        }

        return(
            <div>
                {emps}
            </div>
        );
    }
}