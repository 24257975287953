import React from 'react';
import {Redirect} from 'react-router-dom';

import './VenuesPage.css';

import PageMargins from '../../presentational/PageMargins';
import ModeEditIcon from 'material-ui-icons/ModeEdit'
import AddIcon from 'material-ui-icons/Add';
import Dialog from 'material-ui/Dialog';
import CloseIcon from 'material-ui-icons/Close';

import {List, ListItem} from 'material-ui/List';
import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';
import TextField from 'material-ui/TextField';
import SnackBar from 'material-ui/Snackbar';

export default class VenuePage extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            redirect: null,
            editMode: false,
            editIsNew: false,
            selectedVenue: null,
            selectMode: false,
            ed_heading: "",
            ed_nameVal: "",
            ed_numberVal: "",
            ed_addrVal: "",
            ed_zipVal: "",
            ed_cityVal: "",
            ed_bossVal: "",
            snack_message: ""
        };
    }

    componentWillMount(){
        // componentWillReceiveProps is only called on update
        // Do this to ensure that venuesDict is always created
        this.componentWillReceiveProps(this.props);
        this.props.onEnter("venues");
    }

    componentWillReceiveProps(nextProps){
        if (!nextProps.venues){return}

        // Create venues object
        const new_vens = nextProps.venues.slice();
        const vdict = {};

        if (!new_vens){return;}

        for (let ven of new_vens){
            vdict[ven.venue_id] = ven;
        }

        this.setState({
            ...this.state,
            venuesDict: vdict
        });
    }

    selectVenue = (venue_id) => {
        this.setState({
            redirect: venue_id
        });
    };

    redirectDone = () => {
        this.setState({
            redirect: null
        });
    };

    handleEnterEditMode = (selected_venue) => {
        if (!this.state.venuesDict){
            this.snackAlert("Virksomhetene har ikke lastet inn ferdig. Prøv igjen om et par sekunder.");
            return;
        }
        this.setState({
            ...this.state,
            editMode: true,
            editIsNew: false,
            ed_heading: `Rediger ${this.state.venuesDict[selected_venue].venue_name || "bedrift"}`,
            ed_nameVal: this.state.venuesDict[selected_venue].venue_name,
            ed_numberVal: this.state.venuesDict[selected_venue].venue_nr,
            ed_addrVal: this.state.venuesDict[selected_venue].venue_addr,
            ed_zipVal: this.state.venuesDict[selected_venue].venue_zip,
            ed_cityVal: this.state.venuesDict[selected_venue].venue_city,
            ed_bossVal: this.state.venuesDict[selected_venue].venue_boss,
            selectMode: false,
            selected_venue: selected_venue
        });
    };

    handleEnterAddMode = () => {
        this.setState({
            ...this.state,
            editMode: true,
            editIsNew: true,
            ed_heading: `Legg til ny virksomhet`,
            ed_nameVal: "",
            ed_numberVal: "",
            ed_bossVal: ""
        });
    };

    handleExitEditMode = () => {
        this.setState({
            ...this.state,
            editMode: false,
            editIsNew: false,
            selectedVenue: false
        });
    };

    handleSaveChanges = () => {
        let err = null;

        const b_nr = parseInt(this.state.ed_numberVal, 10);
        const b_name = this.state.ed_nameVal;
        const b_boss = this.state.ed_bossVal;
        const b_addr = this.state.ed_addrVal;
        const b_zip = this.state.ed_zipVal;
        const b_city = this.state.ed_cityVal;

        if (b_nr < 100000000 || b_nr > 999999999) {
            err = "Organisasjonsnummeret skal ha ni siffer."
        } else if (b_name.length < 1){
            err = "Skriv inn firmanavnet."
        } else if (b_name.length > 2000){
            err = "Firmanavnet er for langt."
        } else if (b_boss.length < 1){
            err = "Skriv inn navnet på innehaver eller daglig leder";
        } else if (b_boss.length > 2000){
            err = "Navnet på innehaver eller daglig leder er for langt";
        } else if (b_addr.length < 1){
            err = "Skriv inn virksomhetens adresse.";
        } else if (b_addr.length > 2000){
            err = "Adressen er for lang.";
        } else if (b_zip.length !== 4){
            err = "Postnummer har fire siffer.";
        } else if (!/^\d+$/.test(b_zip)){
            err = "Postnummeret kan kun inneholde tall"
        }else if (b_city < 1){
            err = "Skriv inn poststedet.";
        } else if (b_city > 200){
            err = "Poststedet er for langt.";
        }

        if (err){
            this.snackAlert(err);
        }else if(this.state.editIsNew){
            this.props.addVenue(b_name, b_nr, b_addr, b_zip, b_city, b_boss).then(() => {
                this.snackAlert("Ny virksomhet er lagt til!");
            }).catch(err => {
                this.snackAlert("Det oppstod en feil. Prøv igjen eller kontakt support.");
                console.error(err);
            });
            this.handleExitEditMode();
        }else{
            this.props.editVenue(this.state.selected_venue, b_name, b_nr, b_addr, b_zip, b_city, b_boss).then(() => {
                this.snackAlert("Endringene er lagret");
            }).catch(err => {
                this.snackAlert("Det oppstod en feil. Prøv igjen eller kontakt support.");
                console.error(err);
            });
            this.handleExitEditMode();
        }
    };

    handleNameChange = (event) => {
        this.setState({
            ...this.state,
            ed_nameVal: event.target.value
        });
    };

    handleNumberChange = (event) => {
        this.setState({
            ...this.state,
            ed_numberVal: event.target.value
        });
    };

    handleBossChange = (event) => {
        this.setState({
            ...this.state,
            ed_bossVal: event.target.value
        });
    };

    handleAddrChange = (event) => {
        this.setState({
            ...this.state,
            ed_addrVal: event.target.value
        });
    };

    handleZipChange = (event) => {
        this.setState({
            ...this.state,
            ed_zipVal: event.target.value
        });
    };

    handleCityChange = (event) => {
        this.setState({
            ...this.state,
            ed_cityVal: event.target.value
        });
    };

    handleSnackClose = () => {
        this.setState({
            ...this.state,
            snack_message: ""
        });
    };

    snackAlert = (message) => {
        this.setState({
            ...this.state,
            snack_message: message
        });
    };

    handleEnterSelectMode = () => {
        this.setState({
            ...this.state,
            selectMode: !this.state.selectMode
        });
    };

    render(){
        console.log("Venues page -> rendering", this.props.venues);

        const venue_elements = [];
        if (this.props.venues){
            // Create list elements

            for (let venue of this.props.venues){
                venue_elements.push(
                    <ListItem primaryText={venue.venue_name}
                              secondaryText={venue.venue_nr}
                              rightIcon={this.state.selectMode ? <ModeEditIcon/> : null}
                              key={venue.venue_id}
                              onClick={() => {
                                  if(this.state.selectMode){
                                      this.handleEnterEditMode(venue.venue_id)
                                  }else{
                                      this.selectVenue(venue.venue_id)
                                  }
                              }}
                    />);
            }
        }else{
            venue_elements.push(<ListItem primaryText='Finner ingen virksomheter...' secondaryText='Om du har laget virksomheter tidligere, kontakt support.' key="novenues_item"/>)
        }

        let redir = null;
        if (this.state.redirect){
            redir = <VenueRedirect venue_id={this.state.redirect} redirectDone={this.redirectDone}/>
        }

        return (
            <PageMargins>
                <h1>Virksomheter</h1>
                <List>
                    {venue_elements}
                </List>
                <div className='buttonWrapper'>
                    <div><RaisedButton label='Legg til virksomhet' icon={<AddIcon/>} onClick={this.handleEnterAddMode}/></div>
                    <div><RaisedButton label='Rediger virksomhet' icon={this.state.selectMode ? <CloseIcon/> : <ModeEditIcon/>} onClick={this.handleEnterSelectMode}/></div>
                </div>
                <Dialog open={this.state.editMode}
                        title={this.state.ed_heading}
                        actions={[
                            <FlatButton label='Lagre' onClick={this.handleSaveChanges} style={{color: '#4CAF50'}}/>,
                            <FlatButton label='Avbryt' onClick={this.handleExitEditMode}/>
                        ]}
                >
                    <TextField
                        floatingLabelText='Bedriftens / Virksomhetens navn'
                        onChange={this.handleNameChange}
                        value={this.state.ed_nameVal}
                    />
                    <br/>
                    <TextField
                        floatingLabelText='Bedriftens organisasjonsnummer'
                        type='number' onChange={this.handleNumberChange}
                        value={this.state.ed_numberVal}
                    />
                    <br/>
                    <TextField
                        floatingLabelText='Virksomhetens adresse'
                        hintText=''
                        multiLine={true}
                        rows={2}
                        value={this.state.ed_addrVal}
                        onChange={this.handleAddrChange}
                    />
                    <br/>
                    <TextField
                        floatingLabelText='Postnummer'
                        hintText=''
                        value={this.state.ed_zipVal}
                        style={{marginRight: '1rem'}}
                        onChange={this.handleZipChange}
                    />
                    <TextField
                        floatingLabelText='Poststed'
                        hintText=''
                        value={this.state.ed_cityVal}
                        onChange={this.handleCityChange}
                    />
                    <br/>
                    <TextField
                        floatingLabelText='Navn på innehaver / daglig leder'
                        onChange={this.handleBossChange}
                        value={this.state.ed_bossVal}
                    />
                </Dialog>
                <SnackBar
                    open={this.state.snack_message.length > 0}
                    onRequestClose={this.handleSnackClose}
                    autoHideDuration={4000}
                    message={this.state.snack_message}
                />
                {redir}
            </PageMargins>
        );
    }
}

class VenueRedirect extends React.Component{
    removeMe = () => {
        this.props.redirectDone();
    };

    componentDidMount(){
        this.removeMe();
    }

    render(){
        console.log(this.props.venue_id);
        return <Redirect replace={true} to={'/virksomheter/' + this.props.venue_id}/>;
    }
}