import React from 'react';
import './EmployeeList.css';

import RaisedButton from 'material-ui/RaisedButton';
import Card from 'material-ui/Card';
import TextField from 'material-ui/TextField';

import CloseIcon from 'material-ui-icons/Close';
import DeleteIcon from 'material-ui-icons/Delete';
import UndoIcon from 'material-ui-icons/Undo';

export default class EmployeeList extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            employees: this.props.employees || []
        };
    }

    componentWillReceiveProps(nextProps){
        if (!nextProps.employees){
            return;
        }

        if (!this.props.employees){
            this.setState({
                ...this.state,
                employees: nextProps.employees
            });
            return;
        }

        if (nextProps.employees.length !== this.props.employees.length){
            this.setState({
                ...this.state,
                employees: nextProps.employees
            });
            return;
        }

        let index = 0;
        for (let emp of nextProps.employees){
            for (let key of Object.keys(emp)){
                if (emp[key] !== nextProps.employees[index][key]){
                    this.setState({
                        ...this.state,
                        employees: nextProps.employees
                    });
                    return;
                }
            }
            index += 1;
        }
    }

    addBlankRow = () => {
        let new_employees = JSON.parse(JSON.stringify(this.state.employees)); // this.state.employees.slice();

        new_employees.push({
            name: "",
            ssn: "",
            username: "",
            password: "",
            deleted: false
        });

        this.setState({
            ...this.state,
            employees: new_employees
        });
    };

    // Remove -> this is used for entries that are drafted, but not written to db
    removeRow = (row_index) => {
        let new_employees = JSON.parse(JSON.stringify(this.state.employees)); // this.state.employees.slice();

        new_employees.splice(row_index, 1);

        const new_state = {
            ...this.state,
            employees: new_employees
        };

        this.setState(new_state);
        this.relayChanges(new_state);
    };



    // Delete -> this is used when deleting employees that are already written to the db
    deleteRow = (row_index) => {
        let new_employees = JSON.parse(JSON.stringify(this.state.employees)); //this.state.employees.slice();

        new_employees[row_index].deleted = true;

        const new_state = {
            ...this.state,
            employees: new_employees
        };

        this.setState(new_state);
        this.relayChanges(new_state);
    };

    updateRow = (row_index, row_data) => {
        let new_employees = JSON.parse(JSON.stringify(this.state.employees)); // this.state.employees.slice();

        new_employees[row_index] = row_data;

        const new_state = {
            ...this.state,
            employees: new_employees
        };

        this.setState(new_state);
        this.relayChanges(new_state);
    };

    relayChanges = (new_state) => {
        let not_valid = null;

        if (new_state.employees.length === 0){return;}

        for (let index = 0; index < new_state.employees.length; index += 1){
            let emp = new_state.employees[index];
            if (emp.name.length === 0){
                not_valid = "En ansatt mangler navn."
            }

            if (emp.name.length > 255){
                not_valid = "En ansatt har et for langt navn. Begrens navnet til 255 tegn."
            }

            if (emp.ssn.length === 0){
                not_valid = "En ansatt mangler personnummer eller kode."
            }

            if (emp.ssn.length > 255){
                not_valid = "En ansatt har en for lang kode. Begrens koden til 255 tegn."
            }
        }

        this.props.onUpdate(new_state.employees, not_valid);
    };

    render(){
        let employee_counter = -1;

        return(
            <div>
                <div>
                    <ul className='employeeList'>
                        {!this.state.employees ? null : this.state.employees.map(employee => {
                            employee_counter += 1;
                            return <EmployeeRow key={employee_counter}
                                                data={employee}
                                                row_index={employee_counter}
                                                removeRow={this.removeRow}
                                                deleteRow={this.deleteRow}
                                                updateRow={this.updateRow}
                                                isNew={!employee.hasOwnProperty('employee_id')}
                                                old_data={this.props.employees}
                                                viewDeleted={this.props.viewDeleted}
                            />
                        })}
                    </ul>
                </div>
                <div>
                    <RaisedButton label='Legg til ansatt' onClick={this.addBlankRow}/>
                </div>
            </div>
        );
    }

    componentDidMount(){
        if(this.props.ifEmptyOpenDraft){
            this.addBlankRow();
        }

        this.relayChanges(this.state); // For validation purposes
    }
}

class EmployeeRow extends React.Component{
    removeHandler = () => {
        this.props.removeRow(this.props.row_index);
    };

    deleteHandler = () => {
        console.log("Delete!");
        this.props.deleteRow(this.props.row_index);
    };

    updateHandler = (field, event) => {
        let new_data = Object.assign({}, this.props.data);
        new_data[field] = event.target.value;

        this.props.updateRow(this.props.row_index, new_data);
    };

    restoreHandler = () => {
        let new_data = Object.assign({}, this.props.data);
        new_data.deleted = false;

        this.props.updateRow(this.props.row_index, new_data);
    };

    render(){
        let btn = null;
        if (this.props.isNew){
            btn = <RaisedButton
                icon={<CloseIcon/>}
                label='Fjern'
                backgroundColor='#F44336'
                onClick={this.removeHandler}
            />
        }else if(this.props.data.deleted){
            btn = <RaisedButton
                icon={<UndoIcon/>}
                label='Gjenopprett'
                backgroundColor='#4CAF50'
                onClick={this.restoreHandler}
            />
        }else{
            btn = <RaisedButton
                icon={<DeleteIcon/>}
                label='Slett'
                backgroundColor='#F44336'
                onClick={this.deleteHandler}
            />
        }

        // Check if exists
        if (this.props.old_data){
            for (let old_emp of this.props.old_data){
                if (old_emp.employee_id && this.props.data.employee_id && this.props.data.employee_id === old_emp.employee_id){
                    if (!this.props.viewDeleted && old_emp.deleted){
                        return <div/>;
                    }
                }
            }
        }


        return(
            <li className='employeeWrapper'>
                <Card>
                    {this.props.data.deleted ? <DeletedOverlay/> : null}
                    <div>
                        <div className='employeeTextField'>
                            <TextField
                                floatingLabelText='Navn'
                                onChange={event => {this.updateHandler('name', event)}}
                                value={this.props.data.name}
                            />
                        </div>
                        <div className='employeeTextField'>
                            <TextField
                                floatingLabelText='Personnummer eller kode'
                                onChange={event => {this.updateHandler('ssn', event)}}
                                value={this.props.data.ssn}
                            />
                        </div>
                    </div>
                    {/*<div>*/}
                        {/*<div className='employeeTextField'>*/}
                            {/*<TextField*/}
                                {/*floatingLabelText='Brukernavn (valgfritt)'*/}
                                {/*onChange={event => {this.updateHandler('username', event)}}*/}
                                {/*value={this.props.data.username}*/}
                            {/*/>*/}
                        {/*</div>*/}
                        {/*<div className='employeeTextField'>*/}
                            {/*<TextField*/}
                                {/*floatingLabelText='Passord (valgfritt)'*/}
                                {/*onChange={event => {this.updateHandler('password', event)}}*/}
                                {/*value={this.props.data.password}*/}
                            {/*/>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                    <div style={{display: 'block', textAlign: 'right', paddingRight: '1rem', paddingBottom: '1rem'}}>
                        {btn}
                    </div>
                </Card>
            </li>);
    }
}

class DeletedOverlay extends React.Component{
    render(){
        return <div className='deletedOverlay'><div><div>Slettet</div></div></div>
    }
}