import React from 'react';

import PageMargins from '../../presentational/PageMargins';

export default class TOSPage extends React.Component{
    componentWillMount(){
        this.props.onEnter('TOS');
    }

    render(){
        return(
            <PageMargins>
                <h2>Vilkår for bruk - del 1</h2>
                <ol type='1'>
                    <li>Vilkårene i dette kapitlet gjelder ved all bruk av personalliste.cloud, både før og etter inngåelsen av abonnement. Dette inkluderer bruk i gratisperioden.</li>
                    <li>Personalliste.cloud (heretter “systemet”) er et nettbasert system for personallister.</li>
                    <li>Avtalens parter:
                        <ol type='a'>
                            <li>Alle som bruker systemet er bundet av denne avtalen. Personen som oppretter brukerkontoen (heretter “personlig bruker” blir bundet av avtalen i og med opprettelsen av brukerkontoen.</li>
                            <li>Virksomheter som knyttes til kontoen blir bundet av avtalen i det de legges inn i systemet. Personlig bruker er ansvarlig for å sørge for tilstrekkelige fullmakter fra virksomheten for å kunne binde selskapet.</li>

                        </ol>
                    </li>
                    <li>Bruker tildeles ikke-overførbar bruksrett til systemet. Bruksretten kan nyttes av den personlige brukeren og personer med en naturlig tilknytning til virksomhetene som er registrert på brukerkontoen. Videresalg av tjenesten eller integrasjon i andre produkter er ikke tillatt uten skriftlig tillatelse.</li>
                    <li>Systemet er konstruert etter beste evne for å være forskriftsmessig og i tråd med gjeldende lovgivning for personallister. Bruker har likevel risikoen for at systemet ikke fyller lovens krav, og Tobias Lillestølen Software kan under enhver omstendighet ikke holdes ansvarlig for tap utover det som følger av avtalens punkt 6.</li>
                    <li>Ethvert erstatningsansvar, direkte eller indirekte, er begrenset til det beløpet bruker av systemet har betalt til Tobias Lillestølen Software for bruk av systemet.</li>
                    <li>Tobias Lillestølen Software har en ubegrenset rett til å utestenge personer, brukere eller virksomheter fra å bruke systemet.</li>
                    <li>Integrasjon med andre systemer og maskinell bruk av systemet krever skriftlig tillatelse. Systemet kan ikke brukes av maskiner (roboter) eller integreres i andre datasystemer uten skriftlig tillatelse fra Tobias Lillestølen Software. Systemet kan imidlertid enkelt tilpasses slik bruk, så ta kontakt om dette er ønsket.</li>
                </ol>
                <h2>Vilkår for bruk - del 2</h2>
                <h3>1. Terms</h3>
                <p>By accessing the website at <a href="http://personalliste.cloud">http://personalliste.cloud</a>, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.</p>
                <h3>2. Use License</h3>
                <ol type="a">
                    <li>Permission is granted to temporarily download one copy of the materials (information or software) on Tobias Lillestølen Software's website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
                        <ol type="i">
                            <li>modify or copy the materials;</li>
                            <li>use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
                            <li>attempt to decompile or reverse engineer any software contained on Tobias Lillestølen Software's website;</li>
                            <li>remove any copyright or other proprietary notations from the materials; or</li>
                            <li>transfer the materials to another person or "mirror" the materials on any other server.</li>
                        </ol>
                    </li>
                    <li>This license shall automatically terminate if you violate any of these restrictions and may be terminated by Tobias Lillestølen Software at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.</li>
                </ol>
                <h3>3. Disclaimer</h3>
                <ol type="a">
                    <li>The materials on Tobias Lillestølen Software's website are provided on an 'as is' basis. Tobias Lillestølen Software makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</li>
                    <li>Further, Tobias Lillestølen Software does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.</li>
                </ol>
                <h3>4. Limitations</h3>
                <p>In no event shall Tobias Lillestølen Software or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on Tobias Lillestølen Software's website, even if Tobias Lillestølen Software or a Tobias Lillestølen Software authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</p>
                <h3>5. Accuracy of materials</h3>
                <p>The materials appearing on Tobias Lillestølen Software website could include technical, typographical, or photographic errors. Tobias Lillestølen Software does not warrant that any of the materials on its website are accurate, complete or current. Tobias Lillestølen Software may make changes to the materials contained on its website at any time without notice. However Tobias Lillestølen Software does not make any commitment to update the materials.</p>
                <h3>6. Links</h3>
                <p>Tobias Lillestølen Software has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Tobias Lillestølen Software of the site. Use of any such linked website is at the user's own risk.</p>
                <h3>7. Modifications</h3>
                <p>Tobias Lillestølen Software may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms of service.</p>
                <h3>8. Governing Law</h3>
                <p>These terms and conditions are governed by and construed in accordance with the laws of Oslo, Norge and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p>
                <h2>Vilkår for kjøp - abonnementsvilkår</h2>
                <ol type={'1'}>
                    <li>Bruker av personalliste.cloud kan tegne et abonnement for å bruke systemet ut over gratisperioden. Vilkårene i kapitlet her regulerer abonnementsavtalen.</li>
                    <li>Tobias Lillestølen Software kan når som helst si opp et løpende avtale. Fører oppsigelsen til at systemet blir utilgjengelig for en periode bruker har betalt for skal dette refunderes.</li>
                    <li>Eventuelle prisendringer for aktivt abonnement vil meddeles pr. e-post og vil kommuniseres tydelig minst tre måneder i forveien. Vilkårene for nye abonnement kan endres uten forvarsel.</li>
                    <li>Abonnementet kan sies opp av bruker ved å bruke min-konto-siden. Dette stopper fremtidig fakturering for brukerkontoen. Systemet vil være tilgjengelig for bruk ut den perioden det hittil er betalt for.</li>
                </ol>
            </PageMargins>
        );
    }
};