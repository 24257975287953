import React, {Suspense} from 'react';

import {firebase, auth, firebaseLoaded} from "../firebaseConfig";

import TextField from 'material-ui/TextField';
import Divider from 'material-ui/Divider';
import RaisedButton from 'material-ui/RaisedButton';
import Paper from 'material-ui/Paper';
import CircularProgress from 'material-ui/CircularProgress';
import NavLink from 'react-router-dom/NavLink';

import './LoginModule.css';

const FirebaseAuth = React.lazy(() => import('react-firebaseui/FirebaseAuth'));

export default class LoginModule extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            email: '',
            password: '',
            new_user: this.props.new_user,
            login_loading: false,
            login_error_message: '',
            password_reset_sent: false,
            firebase_loaded: false
        };
    }

    componentDidMount(){
        this.setState({
            ...this.state,
            new_user: this.props.new_user,
            login_loading: false,
            password_reset_sent: false
        });

        if (firebaseLoaded){
            this.initAuth();
        } else {
            window.firebaseLoadedCallbacks.push(this.initAuth);
        }
    }

    componentWillUnmount() {
        window.firebaseLoadedCallbacks = window.firebaseLoadedCallbacks.filter(f => f !== this.initAuth);
    }

    initAuth = () => {
        if (!firebaseLoaded){
            console.warn('LoginModule - firebase not loaded!');
            return;
        }

        this.uiConfig = {
            // Popup signin flow rather than redirect flow.
            signInFlow: 'popup',
            signInSuccessUrl: '/login',
            // We will display Google and Facebook as auth providers.
            signInOptions: [
                firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            ],
            callbacks: {
                // Avoid redirects after sign-in.
                signInSuccess: () => {
                    return false;
                }
            },
            tosUrl: '/tos',
            privacyPolicyUrl: '/personvern'
        };

        this.setState(pendingState => {
            return {
                ...pendingState,
                firebase_loaded: true
            }
        })
    };

    componentWillReceiveProps(nextProps){

    }

    handleFormChange = (field, new_value) => {
        if (field === 'email'){
            this.setState({
                ...this.state,
                email: new_value
            });
        }

        if (field === 'password'){
            this.setState({
                ...this.state,
                password: new_value
            });
        }
    }
    ;

    handleLoginAttempt = () => {
        if (this.state.login_loading || !firebaseLoaded){
            return;
        }

        this.setState({
            ...this.state,
            login_loading: true,
            login_error_message: ''
        });

        if (!this.state.new_user){
            auth.signInWithEmailAndPassword(this.state.email, this.state.password).catch(err => {
                const errorCode = err.code;

                switch (errorCode){
                    case 'auth/invalid-email':
                        this.handleLoginError("E-postadressen er ugyldig. Dobbeltsjekk at den er skrevet riktig.");
                        break;
                    case 'auth/user-disabled':
                        this.handleLoginError("Brukeren har blitt deaktivert. Ta kontakt med support.");
                        break;
                    case 'auth/user-not-found':
                        this.handleLoginError(`Det finnes ingen bruker med denne e-postadressen. For å opprette en ny bruker trykk på linken nederst.`);
                        break;
                    case 'auth/wrong-password':
                        this.handleLoginError("Passordet er feil, eller brukeren bruker facebook eller google som innloggingsmetode.");
                        break;
                    default:
                        this.handleLoginError("Det har oppstått en uventet feil. Ta kontakt med support.");
                        break;
                }
            });
        }else{
            auth.createUserWithEmailAndPassword(this.state.email, this.state.password).catch(err => {
                const errorCode = err.code;

                switch (errorCode){
                    case 'auth/email-already-in-use':
                        this.handleLoginError("Det finnes allerede en bruker med denne e-postadressen.");
                        break;
                    case 'auth/invalid-email':
                        this.handleLoginError("E-postadressen er ugyldig. Dobbeltsjekk at den er skrevet riktig.");
                        break;
                    case 'auth/weak-password':
                        this.handleLoginError(`Passordet er for svakt.`);
                        break;
                    default:
                        this.handleLoginError("Det har har oppstått en uventet feil. Ta kontakt med support");
                        break;
                }
            });
        }
    };

    handleSwitchNewUser = (new_user) => {
        this.setState({
            ...this.state,
            new_user: new_user
        });
    };

    handleLoginError = (error_message) => {
        this.setState({
            ...this.state,
            login_error_message: error_message,
            login_loading: false
        });
    };

    handlePasswordResetRequest = () => {
        this.setState({
            ...this.state,
            password_reset_sent: "Sender e-post..."
        });

        auth.languageCode = 'no'; // Norsk reset e-post
        auth.sendPasswordResetEmail(this.state.email).then(() => {
            this.setState({
                ...this.state,
                password_reset_sent: "Vi har sendt deg en e-post du kan bruke for å velge et nytt passord"
            });
        }).catch(err => {
            this.setState({
                ...this.state,
                password_reset_sent: false
            });

            switch (err.code){
                case 'auth/invalid-email':
                    this.handleLoginError("E-postadressen er ugyldig. Dobbeltsjekk at den er skrevet riktig.");
                    break;
                case 'auth/user-not-found':
                    this.handleLoginError('Det finnes ingen bruker med denne e-postadressen.');
                    break;
                default:
                    console.error(err);
                    this.handleLoginError("Det har har oppstått en uventet feil. Ta kontakt med support");
                    break;
            }
        });
    };

    render(){
        return (
            <div className='loginModuleWrapper'>
                <div>
                    <h2>{this.state.new_user ? 'Opprett ny bruker' : 'Logg inn'}</h2>
                    {this.state.login_error_message.length > 0 ? <p className='loginModuleLoginError'>{this.state.login_error_message}</p> : null}
                    <Paper zDepth={2}>
                        <TextField
                            type='email'
                            hintText="E-post"
                            value={this.state.email}
                            onChange={event => {this.handleFormChange('email', event.target.value)}}
                            underlineShow={false}
                        />
                        <Divider/>
                        <TextField
                            hintText="Passord"
                            type='password'
                            value={this.state.password}
                            onChange={event => {this.handleFormChange('password', event.target.value)}}
                            underlineShow={false}
                        />
                        <Divider/>
                        <br/>
                        <div style={{minWidth: '150px', display: 'inline-block'}}>
                            <RaisedButton
                                label={this.state.login_loading ? '' : this.state.new_user ? 'Opprett bruker' : 'Logg inn'}
                                icon={this.state.login_loading ? <div className='loginModuleLoadingWrapper'><CircularProgress color='white' size={24}/></div> : ''}
                                onClick={this.handleLoginAttempt}
                                backgroundColor='#4CAF50'
                                fullWidth={true}
                            />
                        </div>
                        {this.state.new_user ? null : this.state.password_reset_sent ? <div><p className='loginModulePasswordResetInfo'>{this.state.password_reset_sent}</p></div> : <div><p className='loginModuleNewUserSwitch' onClick={this.handlePasswordResetRequest}>Jeg har glemt passordet</p></div>}
                        <div>
                            <p className='loginModuleTOS'>
                                Ved å {this.state.new_user ? 'opprette en bruker' : 'logge inn'} samtykker du til våre <NavLink to='/tos'>vilkår</NavLink> og vår <NavLink to='/personvern'>personvernerklæring</NavLink>
                            </p>
                        </div>
                    </Paper>
                    <br/>
                    <div>
                        {
                            this.state.firebase_loaded ? (
                                <Suspense fallback={<div>...</div>}>
                                    <FirebaseAuth
                                        uiConfig={this.uiConfig}
                                        firebaseAuth={auth}
                                        uiCallback={ui => {console.log(ui)}}
                                    />
                                </Suspense>) : null
                        }
                    </div>
                    {
                        this.state.new_user ? <p className='loginModuleNewUserSwitch' onClick={() => {this.handleSwitchNewUser(false);}}>Jeg har allerede en bruker</p> : <p className='loginModuleNewUserSwitch' onClick={() => {this.handleSwitchNewUser(true);}}>Opprett en ny bruker</p>
                    }
                </div>
            </div>
        )
    }
}