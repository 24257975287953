import React from 'react';

import PageMargins from '../../presentational/PageMargins';

export default class PrivacyPage extends React.Component{
    componentWillMount(){
        this.props.onEnter('Privacy');
    }

    render(){
        return(
            <PageMargins>
                <h1>Personvernerklæring</h1>
                <h3>Behandlingsansvarlig</h3>
                <p>
                    Behandlingsansvarlig for personalliste.cloud er Tobias Lillestølen.<br/>
                    Søndre Gate 2<br/>
                    0550 Oslo<br/>
                    Norge
                </p>
                <h3>Generelt</h3>
                <p>
                    Ingen personopplysninger vil selges til andre. Betalingsinformasjon (navn, adresse, e-post og kortinformasjon) deles med vår betalingsløsningleverandør Stripe. Se <a href='https://stripe.com'>stripe.com</a>. Foruten dette vil ingen personopplysninger deles med andre. E-postadressen din kan en sjelden gang bli brukt til å sende deg markedsføring for våre tjenester, men den vil aldri brukes til markedsføring for tredjeparter. Du kan få innsyn i all informasjon som er lagret under din bruker når som helst ved å ta kontakt på e-post <a href="mailto:kontakt@personalliste.cloud">kontakt@personalliste.cloud</a> eller telefon 90821081. Se også personopplysningsloven § 18, 27 og 28. Du kan kreve retting av mangelfulle uriktige opplysninger.
                </p>
                <h3>Hvilken informasjon lagres</h3>
                <p>Ved bruk av personalliste.cloud lagres navn og e-postadresse på brukeren for å personalisere opplevelsen og for å kunne kontakte deg.</p>
                <p>Om du tegner abonnementet vil også betalerens navn og adresse lagres. Dette er nødvendig for å kunne generere fakturaer/kvitteringer. Vi lagrer ikke kortinformasjonen din, men kortinformasjon, navn, adresse og e-post vil bli lagret hos vår betalingsløsningleverandør Stripe. Se <a href='https://stripe.com'>stripe.com</a>.</p>
                <p>Navn og eventuelt personnummer (valgfritt) på de ansatte som legges inn i systemet lagres. Dette er nødvendig for å gi en god brukeropplevelse.</p>
                <p>Tidspunkt for inn- og utstempling for de ansatte lagres. Dette må vi gjøre for at systemet skal ha noen funksjon.</p>
                <p>Hjemmelen for behandlingen av personopplysningene er samtykke.</p>
                <h3>Hvordan samles informasjonen inn</h3>
                <p>Navnet til brukeren samles inn ved at man logger inn ved å bruke facebook eller google.</p>
                <p>Alle øvrige opplysninger samles inn ved at brukeren eksplisitt fyller informasjonen inn i skjema på siden.</p>
                <h3>Arkivering og sletting</h3>
                <p>Opplysningene lagres i vår database som kjører på Google sine servere. For å kunne vise opplysningene må man være logget inn - enten som brukeren selv, eller gjennom ansattsiden, dersom denne funksjonen er skrudd på.</p>
                <p>Opplysningene slettes ikke automatisk, men du kan når som helst be om at de skal slettes ved å ta kontakt med behandlingsansvarlig.</p>
                <h3>Øvrig</h3>
                <p>Personvernet på personalliste.cloud faller under norsk rett.</p>
            </PageMargins>
        );
    }
}