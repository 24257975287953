import React from 'react';
import {Redirect} from 'react-router-dom';

// Material UI
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import SnackBar from 'material-ui/Snackbar';

// Presentational
import PageMargins from '../../presentational/PageMargins';
import EmployeeList from '../EmployeeList/EmployeeList';


export default class FirstLogin extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            employee_list_data: null,
            employee_list_valid_error: null,
            validation_message: "",
            business_name: "",
            business_nr: "",
            business_boss: "",
            business_addr: "",
            business_zip: "",
            business_city: "",
            submit_redirect: false
        }
    }

    componentWillMount(){
        this.props.onEnter("first_login_page");
    }

    handleEmployeeListUpdate = (new_data, valid_error) => {
        this.setState({
            ...this.state,
            employee_list_data: new_data,
            employee_list_valid_error: valid_error
        });
    };

    handleBusinessUpdate = (field, new_val) => {
        if (field === 'name'){
            this.setState({
                ...this.state,
                business_name: new_val
            });
        }

        if (field === 'number'){
            this.setState({
                ...this.state,
                business_nr: new_val
            });
        }

        if (field === 'boss'){
            this.setState({
                ...this.state,
                business_boss: new_val
            });
        }

        if (field === 'zip'){
            this.setState({
                ...this.state,
                business_zip: new_val
            });
        }

        if (field === 'addr'){
            this.setState({
                ...this.state,
                business_addr: new_val
            });
        }

        if (field === 'city'){
            this.setState({
                ...this.state,
                business_city: new_val
            });
        }
    };

    validateFields = () => {
        let err = null;

        if (this.state.employee_list_valid_error) {
            err = this.state.employee_list_valid_error;
        } else if (this.state.business_nr < 100000000 || this.state.business_nr > 999999999) {
            err = "Organisasjonsnummeret skal ha ni siffer.";
        } else if (this.state.business_name.length < 1){
            err = "Skriv inn firmanavnet.";
        } else if (this.state.business_name.length > 2000){
            err = "Firmanavnet er for langt.";
        } else if (this.state.business_addr.length < 1){
            err = "Skriv inn virksomhetens adresse.";
        } else if (this.state.business_addr.length > 2000){
            err = "Adressen er for lang.";
        } else if (this.state.business_zip.length !== 4){
            err = "Postnummer har fire siffer.";
        } else if (!/^\d+$/.test(this.state.business_zip)){
            err = "Postnummeret kan kun inneholde tall"
        }else if (this.state.business_city.length < 1){
            err = "Skriv inn poststedet.";
        } else if (this.state.business_city.length > 200){
            err = "Poststedet er for langt.";
        } else if (this.state.business_boss.length < 1){
            err = "Skriv inn navnet på virksomhetens innehaver eller daglig leder.";
        } else if (this.state.business_boss.length > 2000){
            err = "Navnet på innehaver eller daglig leder er for langt.";
        }

        if (err) {
            this.setState({
                ...this.state,
                validation_message: err
            });
            return false;
        }

        return true;
    };

    handleConfirm = () => {
        if (this.validateFields()){
            console.log("Confirming!");
            this.props.firestoreAddStart(
                this.state.business_name,
                this.state.business_nr,
                this.state.business_addr,
                this.state.business_zip,
                this.state.business_city,
                this.state.business_boss,
                this.state.employee_list_data);
            this.setState({
                submit_redirect: true
            });
        }
    };

    handleRedirectComplete = () => {
        this.setState({
            submit_redirect: false
        });
    };

    handleSnackbarClose = () => {
        this.setState({
            ...this.state,
            validation_message: ""
        })
    };

    render(){
        return(
            <PageMargins>
                <h1>Velkommen</h1>
                <h3>Informasjon om virksomheten</h3>
                <p>Hver virksomhet skal ha sin egen personalliste. Legg inn informasjonen om din første virksomhet her. Du får muligheten til å legge til så mange virksomheter du ønsker senere.</p>
                <TextField
                    floatingLabelText="Virksomhetens navn"
                    hintText=""
                    value={this.state.business_name}
                    onChange={event => {this.handleBusinessUpdate('name', event.target.value)}}
                />
                <br/>
                <TextField
                    floatingLabelText="Organisasjonsnummer"
                    hintText=""
                    value={this.state.business_nr}
                    type="number"
                    onChange={event => {this.handleBusinessUpdate('number', event.target.value)}}
                />
                <br/>
                <TextField
                    floatingLabelText='Virksomhetens adresse'
                    hintText=''
                    multiLine={true}
                    rows={2}
                    value={this.state.business_addr}
                    onChange={event => {this.handleBusinessUpdate('addr', event.target.value)}}
                />
                <br/>
                <TextField
                    floatingLabelText='Postnummer'
                    hintText=''
                    value={this.state.business_zip}
                    style={{marginRight: '1rem'}}
                    onChange={event => {this.handleBusinessUpdate('zip', event.target.value)}}
                />
                <TextField
                    floatingLabelText='Poststed'
                    hintText=''
                    value={this.state.business_city}
                    onChange={event => {this.handleBusinessUpdate('city', event.target.value)}}
                />
                <br/>
                <TextField
                    floatingLabelText='Navn på virksomhetens innehaver eller daglig leder'
                    style={{minWidth: '100%'}}
                    hintText=""
                    value={this.state.business_boss}
                    onChange={event => {this.handleBusinessUpdate('boss', event.target.value)}}
                />
                <br/><br/>

                <h3>Informsajon om de ansatte</h3>
                <p>Det er ikke pålagt å identifisere de ansatte med fødselsnummer. Om du ikke ønsker å bruke fødselsnummer som identifikasjon må du lage et eget kodeord for hver ansatt. Det kan f.eks. være fornavn "peder", eller initialer + fødselsår (Peder Ås, født i 1955 kan få koden "PÅ55"). Du må da skrive ned en liste over hvilket fødselsnummer som knytter seg til hvert kodeord, og ha listen tilgjengelig ved kontroll.</p>
                <EmployeeList onUpdate={this.handleEmployeeListUpdate} ifEmptyOpenDraft={true}/>
                <br/><br/>
                <div style={{textAlign: 'center'}}>
                    <RaisedButton
                        label="Ferdig"
                        onClick={this.handleConfirm}
                        backgroundColor='#4CAF50'
                    />
                </div>
                <SnackBar
                    open={this.state.validation_message.length !== 0}
                    message={this.state.validation_message}
                    autoHideDuration={4000}
                    onRequestClose={this.handleSnackbarClose}
                />
                {this.state.submit_redirect ? <SubmitRedirectComponent handleRedirectComplete={this.handleRedirectComplete}/> : null}
            </PageMargins>
        );
    }
}

class SubmitRedirectComponent extends React.Component{
    componentDidMount(){
        this.props.handleRedirectComplete();
    }

    render(){
        return <Redirect to='/virksomheter'/>;
    }
}