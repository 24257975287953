let testModeActive = false;

if (testModeActive){
    console.log('%cApp is running in test mode! ', 'background: #222; color: #bada55; font-size: 2rem');
} else {
    console.log('App is running in live mode');
}

const TEST_KEY = 'pk_test_5X0sCkq39t8kML5kdSe7bEqR';
const LIVE_KEY = 'pk_live_AK2ithFHPT97c3mfLrzWxZVX';

const stripeKey = testModeActive ? TEST_KEY : LIVE_KEY;

export { testModeActive, stripeKey };