import React from 'react';

import {CardElement, injectStripe} from "react-stripe-elements";
import {functions} from "../firebaseConfig";
import {plans} from "../../data/plans";
import {testModeActive} from "../../data/appConfig";
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/css/react-flags-select.css';

// Core components
import RaisedButton from 'material-ui/RaisedButton';
import {green500} from 'material-ui/styles/colors';
import CircularProgress from 'material-ui/CircularProgress';

import './CheckoutForm.css';
import CreditCardIcon from 'material-ui-icons/CreditCard';
import {NavLink} from "react-router-dom";


class CheckoutForm extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            cardInfo: {
                name: '',
                address: '',
                city: '',
                postalCode: '',
                country: 'NO'
            },
            errorMessage: null,
            pendingTokenSubmit: false,
            dialog: null
        }
    }

    disableSubmit = false;
    disableCallbacks = false;

    componentWillMount() {
        this.disableCallbacks = false;
    }

    componentWillUnmount() {
        this.disableCallbacks = true;
    }

    handleSubmit = () => {
        if (this.disableSubmit){return;}
        this.disableSubmit = true;

        this.setState(pendingState => {
            return {
                ...pendingState,
                pendingTokenSubmit: true,
                errorMessage: null
            }
        }, () => {
            this.props.stripe.createToken({
                type: 'card',
                name: this.state.cardInfo.name,
                address_line1: this.state.cardInfo.address,
                address_city: this.state.cardInfo.city,
                address_zip: this.state.cardInfo.postalCode,
                address_country: this.state.cardInfo.country
            }).then(res => {
                if (res.hasOwnProperty('error')){
                    this.setState(pendingState => {
                        return {
                            ...pendingState,
                            errorMessage: res.error.message,
                            pendingTokenSubmit: false
                        }
                    });
                    this.disableSubmit = false;
                } else {
                    const submitPaymentToken = functions.httpsCallable('createSubscription');
                    const token = res.token;
                    console.log(token);

                    submitPaymentToken({
                        plan: plans.basicMonthly.planId,
                        token: token,
                        testMode: testModeActive
                    }).then(res => {
                        console.log('createSubscription response: ', res);
                        if (res.data.status === 'success'){
                            console.log('SUCCESS!');
                            if (this.disableCallbacks){return;}

                            this.props.showPaymentDialog({
                                status: 'success',
                                title: 'Alt er klart!',
                                message: 'Takk for at du bruker personalliste.cloud. Kortet vil bli belastet automatisk hver måned.'
                            });

                            this.setState(pendingState => {
                                return {
                                    ...pendingState,
                                    pendingTokenSubmit: false
                                };
                            })
                        } else {
                            if (this.disableCallbacks){return;}
                            this.setState(pendingState => {
                                this.props.showPaymentDialog({
                                    status: 'error',
                                    title: 'Kunne ikke anvende dette kortet.',
                                    message: res.message || 'Sjekk at kortinformasjonen stemmer. Ta evt. kontakt med support på kontakt@personalliste.cloud'
                                });

                                return {
                                    ...pendingState,
                                    pendingTokenSubmit: false,
                                };
                            });
                        }
                    }).catch(err => {
                        console.error('Unexpected error', err);
                    }).finally(() => {
                        this.disableSubmit = false;
                    })
                }
            });
        });
    };

    updatePaymentMethod = () => {
        if (this.disableSubmit){return;}
        this.disableSubmit = true;

        console.log('Updating payment method...');

        this.setState(pendingState => {
            return {
                ...pendingState,
                pendingTokenSubmit: true,
                errorMessage: null
            }
        }, () => {
            this.props.stripe.createToken({
                type: 'card',
                name: this.state.cardInfo.name,
                address_line1: this.state.cardInfo.address,
                address_city: this.state.cardInfo.city,
                address_zip: this.state.cardInfo.postalCode,
                address_country: this.state.cardInfo.country
            }).then(res => {
                if (res.hasOwnProperty('error')){
                    this.setState(pendingState => {
                        return {
                            ...pendingState,
                            errorMessage: res.error.message,
                            pendingTokenSubmit: false
                        }
                    });
                    this.disableSubmit = false;
                } else {
                    const updatePaymentMethod = functions.httpsCallable('updatePaymentMethod');
                    const token = res.token;
                    console.log(token);

                    updatePaymentMethod({
                        plan: plans.basicMonthly.planId,
                        token: token,
                        testMode: testModeActive
                    }).then(res => {
                        console.log('createSubscription response: ', res);
                        if (res.data.status === 'success'){
                            console.log('SUCCESS!');
                            this.props.showPaymentDialog({
                                status: 'success',
                                title: 'Betalingsmetode oppdatert!',
                                message: 'Betalinger vil heretter belastes det nye kortet.'
                            });

                            this.setState(pendingState => {
                                return {
                                    ...pendingState,
                                    pendingTokenSubmit: false,
                                };
                            }, () => {
                                this.props.hideUpdatePayment();
                            });
                        } else {
                            this.props.showPaymentDialog({
                                status: 'error',
                                title: 'Kunne ikke belaste kortet.',
                                message: res.message || 'Sjekk at kortinformasjonen stemmer. Ta evt. kontakt med support på kontakt@personalliste.cloud'
                            });

                            this.setState(pendingState => {
                                return {
                                    ...pendingState,
                                    pendingTokenSubmit: false,
                                };
                            });
                        }
                    }).catch(err => {
                        console.error('An unexpected error has occurred: ', err);
                    }).finally(() => {
                        this.disableSubmit = false;
                    });
                }
            });
        });
    };

    updateField = field => event => {
        let newVal = event.target.value;

        // validation
        if (field === 'postalCode'){
            if (newVal.length > 4){newVal = newVal.substring(0, 4);}
            newVal = newVal.replace(/\D/g,''); // remove all non-numeric
        }

        this.setState(pendingState => {
            return {
                ...pendingState,
                cardInfo: {
                    ...pendingState.cardInfo,
                    [field]: newVal
                },
                errorMessage: null
            }
        });
    };

    updateCountry = newCode => {
        this.setState(pendingState => {
            return {
                ...pendingState,
                cardInfo: {
                    ...pendingState.cardInfo,
                    country: newCode
                },
                errorMessage: null
            }
        })
    };


    render(){
        const userDataPending = !this.props.fsUser;

        const existingSubscription = (
            !!this.props.fsUser &&
            this.props.fsUser.hasOwnProperty('stripeSubscriptionId') &&
            typeof this.props.fsUser.stripeSubscriptionId === 'string' &&
            this.props.fsUser.stripeSubscriptionId.length > 0
        );

        return(
            <div className={'checkout-form-container'}>
                <div style={{display: 'flex', flexFlow: 'row wrap', width: '100%'}}>
                    <div className={'checkout-form-row'} style={{flex: '1 100%', marginBottom: '1rem'}}>
                        <CardElement/>
                    </div>
                    <div className={'checkout-form-row'} style={{flex: '1 100%'}}>
                        <input
                            type={'text'}
                            className={'card-info-input'}
                            placeholder={'Kortholders Navn'}
                            onChange={this.updateField('name')}
                            value={this.state.cardInfo.name}
                        />
                    </div>
                    <div className={'checkout-form-row'} style={{flex: '1 100%'}}>
                        <input
                            type={'text'}
                            className={'card-info-input'}
                            placeholder={'Adresse'}
                            onChange={this.updateField('address')}
                            value={this.state.cardInfo.address}
                        />
                    </div>
                    <div className={'checkout-form-row'} style={{flex: '1'}}>
                        <input
                            type={'text'}
                            className={'card-info-input'}
                            placeholder={'By'}
                            onChange={this.updateField('city')}
                            value={this.state.cardInfo.city}
                        />
                    </div>
                    <div className={'checkout-form-row'} style={{flex: '1'}}>
                        <input
                            type={'number'}
                            max={"9999"}
                            className={'card-info-input'}
                            placeholder={'Postnummer'}
                            onChange={this.updateField('postalCode')}
                            value={this.state.cardInfo.postalCode}
                        />
                    </div>
                    <div className={'checkout-form-row'} style={{flex: '1 100%'}}>
                        <ReactFlagsSelect
                            searchable={true}
                            defaultCountry={'NO'}
                            placeholder={'Land'}
                            selectedSize={14}
                            className={'card-info-input'}
                            onSelect={this.updateCountry}
                            customLabels={{NO: 'Norge'}}
                        />
                    </div>
                    { this.state.errorMessage ? <div className={'checkout-form-error-message'}>{ this.state.errorMessage }</div> : null}
                    { !existingSubscription ? <div className={'checkout-form-tos'}>Ved å bekrefte godtar du <NavLink to={'/tos'}>vilkår for bruk</NavLink>.</div> : null}
                    { !userDataPending ? (
                        <RaisedButton
                            onClick={existingSubscription ? this.updatePaymentMethod : this.handleSubmit}
                            className={'checkout-form-submit'}
                            backgroundColor={green500}
                            icon={<CreditCardIcon/>}
                            label={existingSubscription ? 'Bytt betalingskort' : 'Start abonnement'}
                    />) : false}
                </div>
                {
                    this.state.pendingTokenSubmit ? (
                        <div className={'checkout-form-loading-container'}>
                            <div className={'checkout-form-loading'}>
                                <div><CircularProgress/></div>
                                <div><p>Vennligst vent...</p></div>
                            </div>
                        </div>
                    ) : null
                }

            </div>
        )
    }
}

export default injectStripe(CheckoutForm);