import React, { Component } from 'react';
import './App.css';

// Material UI
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

// React router
import { BrowserRouter } from 'react-router-dom';

// Firebase container
import FirebaseContainer from './containers/FirebaseContainer';

import moment from 'moment';
import('moment-timezone').then(tz => {
    moment.tz.setDefault("Europe/Oslo");
});

class App extends Component {
    render() {
        return (
        <MuiThemeProvider>
            <BrowserRouter>
                <FirebaseContainer/>
            </BrowserRouter>
        </MuiThemeProvider>
    );
  }
}

export default App;
