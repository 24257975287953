import { testModeActive } from "./appConfig";

const plans = {
    basicMonthly: {
        name: 'Enkel',
        desc: 'Kun én virksomhet.',
        planId: testModeActive ? 'plan_CbrbAlOjmSugUe' : 'plan_CbybxTQTLl3q5p',
        price: 79
    },
};

export {plans};