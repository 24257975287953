import React from 'react';

import {List, ListItem} from 'material-ui/List';

import DoneIcon from 'material-ui-icons/Done';
import WarningIcon from 'material-ui-icons/Warning';
import Dialog from 'material-ui/Dialog';
import Divider from 'material-ui/Divider';
import {
    Table,
    TableBody,
    TableHeader,
    TableHeaderColumn,
    TableRow,
    TableRowColumn,
} from 'material-ui/Table';

import './InvoiceModule.css';

import moment from 'moment';
import {firestore} from "../firebaseConfig";

export default class InvoiceModule extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            invoices: [],
            invoiceIdOpen: null,
            debugClicks: 0
        };

        this.invoices = [];
        this.payments = [];
        this.payment_errors = [];
        this.invoiceListener = null;
    }

    componentWillMount(){
        this.attemptCreateListener();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.fsUser && this.props.fsUser.uid &&
            ((!prevProps.fsUser || !prevProps.fsUser.uid) || prevProps.fsUser.uid !== this.props.fsUser.uid)
        ){
            this.attemptCreateListener();
        }
    }

    componentWillUnmount(){
        if (this.invoiceListener){
            this.invoiceListener();
        }
    }

    attemptCreateListener = () => {
        if (
            !this.props.fsUser ||
            !this.props.fsUser.uid
        ){return;}

        if (this.invoiceListener){this.invoiceListener();}

        const uid = this.props.fsUser.uid;
        this.invoiceListener = firestore.collection(`private_data/${uid}/invoices`).onSnapshot(snaps => {
            const invoices = [];
            const invoicesDict = {};

            snaps.forEach(doc => {
                const data = doc.data();
                if (data && data.invoice.total > 0){
                    invoices.push(doc.data());
                }

                invoicesDict[data.invoice.id] = data;
            });

            invoices.sort((a, b) => {return a.invoice.date - b.invoice.date});

            console.log(invoices);
            this.setState(pendingState => {
                return {
                    ...pendingState,
                    invoices: invoices,
                    invoicesDict: invoicesDict
                }
            });
        });
    };

    handleSelectInvoice = (invoice_id) => {
        this.setState({
            ...this.state,
            invoiceIdOpen: invoice_id
        });
    };

    handleInvoiceClose = () => {
        this.setState({
            ...this.state,
            invoiceIdOpen: null
        })
    };

    handleDebugClick = () => {
        this.setState(pendingState => {
            return {
                ...pendingState,
                debugClicks: pendingState.debugClicks + 1
            }
        });
    };

    render(){
        if (!this.props.fsUser || !this.props.fsUser.uid){return null;}

        // console.log(this.state.invoiceDict);
        const injectedData = {};
        ['name', 'addr', 'city', 'zip', 'country'].forEach(field => {
            injectedData[field] = '...';

            if (this.state.invoiceIdOpen){
                if (
                    this.state.invoicesDict[this.state.invoiceIdOpen].hasOwnProperty('injectedInvoiceInfo') &&
                    this.state.invoicesDict[this.state.invoiceIdOpen].injectedInvoiceInfo.hasOwnProperty(field)
                ){
                    injectedData[field] = this.state.invoicesDict[this.state.invoiceIdOpen].injectedInvoiceInfo[field];
                }
            }
        });

        const invoiceNumber = this.state.invoiceIdOpen ? this.state.invoicesDict[this.state.invoiceIdOpen].invoice.number : '...';
        const invoiceDate = this.state.invoiceIdOpen ? moment.unix(this.state.invoicesDict[this.state.invoiceIdOpen].invoice.date).format('DD.MM.YYYY') : '...';
        const invoicePrice = this.state.invoiceIdOpen ? Math.floor(this.state.invoicesDict[this.state.invoiceIdOpen].invoice.total/100).toString() + ",00 Kr" : '...';

        return(
            <div>
                {
                    this.state.debugClicks > 5 && this.props.fsUser ? (
                        <div>
                            <div>{moment.unix(this.props.fsUser.paid_until).format('YYYY-MM-DD HH:mm')} paid until</div>
                            <div>{moment.unix(this.props.fsUser.freeTrial_until).format('YYYY-MM-DD HH:mm')} free trial end</div>
                            <div>{moment.unix(this.props.fsUser.signup_ts).format('YYYY-MM-DD HH:mm')} signup</div>
                        </div>
                    ) : null
                }
                <h3 onClick={this.handleDebugClick}>Mine fakturaer</h3>
                {this.state.invoices.length === 0 ? <div>
                    Du har ingen fakturaer enda.
                </div> : <List>
                    {
                        this.state.invoices.map(invoice => {
                            return(
                                <a href={`https://us-central1-personalliste-da8a6.cloudfunctions.net/generateInvoice?uid=${this.props.fsUser.uid}&invoiceId=${invoice.invoice.id}`}
                                   target={'_blank'}
                                   key={invoice.invoice.id}
                                >
                                    <ListItem
                                        rightIcon={invoice.paid ? <DoneIcon color='#4CAF50'/> : <WarningIcon color='#F44336'/>}
                                        // onClick={() => {this.handleSelectInvoice(invoice.invoice.id)}}
                                    >
                                            Faktura nr. {invoice.invoice.number}<span style={{float: 'right'}}>{invoice.paid ? 'Betalt' : 'Ikke betalt'}</span>
                                    </ListItem>
                                </a>
                            );
                        })
                    }
                </List>}
                <Dialog open={!!this.state.invoiceIdOpen}
                        title={`Faktura nummer ${invoiceNumber}`}
                        onRequestClose={this.handleInvoiceClose}
                >
                    <div className='invoiceModuleRightAlign'>
                        <div className='invoiceModuleInlineBlock'>
                            <div className='invoiceModuleLeftAlign'>
                                E-post:<br/>
                                Telefon:
                            </div>
                            <div className='invoiceModuleRightAlign'>
                                <a href='mailto:kontakt@personalliste.cloud'>kontakt@personalliste.cloud</a><br/>
                                90821081
                            </div>
                            <div className='invoiceModuleClear'/>
                        </div>
                        <div className='invoiceModuleInlineBlock' style={{marginLeft: '2rem'}}>
                            Org.nr: 920 671 950<br/>
                            Foretaksregisteret
                        </div>
                    </div>
                    <div className='invoiceModuleClear'/>
                    <div style={{marginTop: '2rem'}}>
                        <div className='invoiceModuleInfoBlock'>
                            <div className='invoiceModuleInfoBlockHeading'>Til</div>
                            <Divider/>
                            <div>
                                {injectedData.name}<br/>
                                {injectedData.addr}<br/>
                                {injectedData.zip}<br/>
                                {injectedData.city}<br/>
                                {injectedData.country}
                            </div>
                        </div>
                        <div className='invoiceModuleInfoBlock'>
                            <div className='invoiceModuleInfoBlockHeading'>Fra</div>
                            <Divider/>
                            <div>
                                Tobias Lillestølen Software<br/>
                                Søndre Gate 2<br/>
                                0550<br/>
                                Oslo<br/>
                                Norge
                            </div>
                        </div>
                        <div className='invoiceModuleInfoBlock'>
                            <div className='invoiceModuleInfoBlockHeading'>
                                {`Faktura nummer ${invoiceNumber}`}
                            </div>
                            <Divider/>
                            <div>
                                <div className='invoiceModuleLeftAlign'>
                                    Fakturadato<br/>
                                    Forfallsdato<br/>
                                    Å betale
                                </div>
                                <div className='invoiceModuleRightAlign'>
                                    {invoiceDate}<br/>
                                    {invoiceDate}<br/>
                                    {invoicePrice}
                                </div>
                                <div className='invoiceModuleClear'/>
                            </div>
                        </div>
                        <div className='invoiceModuleSpacer'/>
                        <Table selectable={false}>
                            <TableHeader displaySelectAll={false}
                                         adjustForCheckbox={false}
                                         enableSelectAll={false}>
                                <TableRow>
                                    <TableHeaderColumn>Beskrivelse</TableHeaderColumn>
                                    <TableHeaderColumn style={{width: '4rem', textAlign: 'right'}}>Enhetspris</TableHeaderColumn>
                                    <TableHeaderColumn style={{width: '4rem', textAlign: 'right'}}>Antall</TableHeaderColumn>
                                    <TableHeaderColumn style={{width: '4rem', textAlign: 'right'}}>Mva</TableHeaderColumn>
                                    <TableHeaderColumn style={{width: '4rem', textAlign: 'right'}}>Sum</TableHeaderColumn>
                                </TableRow>
                            </TableHeader>
                            <TableBody displayRowCheckbox={false}>
                                <TableRow>
                                    <TableRowColumn>Skybasert personalliste</TableRowColumn>
                                    <TableRowColumn style={{width: '4rem', textAlign: 'right'}}>{invoicePrice}</TableRowColumn>
                                    <TableRowColumn style={{width: '4rem', textAlign: 'right'}}>1</TableRowColumn>
                                    <TableRowColumn style={{width: '4rem', textAlign: 'right'}}>0%</TableRowColumn>
                                    <TableRowColumn style={{width: '4rem', textAlign: 'right'}}>{invoicePrice}</TableRowColumn>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <div className='invoiceModuleSpacer'/>
                        <div>
                            <div className='invoiceModuleRightAlign'>
                                <div className='invoiceModuleInlineBlock' style={{textAlign: 'right'}}>
                                    Netto<br/>
                                    Mva<br/>
                                    Å betale
                                </div>
                                <div className='invoiceModuleInlineBlock invoiceModuleSum' style={{textAlign: 'right'}}>
                                    {invoicePrice}<br/>
                                    0,00 kr<br/>
                                    {invoicePrice}
                                </div>
                            </div>
                            <div className='invoiceModuleClear'/>
                        </div>
                    </div>
                </Dialog>
            </div>
        );
    }
}