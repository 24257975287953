import React from 'react';

import './StampForm.css';

import moment from 'moment';

// Material UI
import Paper from 'material-ui/Paper';
import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';

export default class StampForm extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            submit_loading: false,
            submit_error: null,
            submit_log_id: null
        };
    }

    componentDidMount() {
        if (!this.listener){
            this.listener = this.props.listenForLastStamp(this.props.venue_id, this.props.employee.employee_id);
        }
    }

    componentDidUpdate(){
        if (this.state.submit_loading && this.props.last_stamp && this.props.last_stamp.log_id === this.state.submit_log_id){
            this.setState({
                ...this.state,
                submit_log_id: null,
                submit_loading: false
            });
        }
    }

    componentWillUnmount(){
        // Destroy listener
        if (this.listener){
            this.listener();
        }
    }

    stamp = (out) => {
        if (this.state.submit_loading){
            return;
        }

        if ((this.props.fsUser && this.props.fsUser.disabled) || this.props.employeeDisabled){
            this.props.openDisabledSnack();
            return;
        }

        const ret = this.props.addStamp(this.props.venue_id, this.props.employee.employee_id, out);
        const log_id = ret.id;

        this.setState({
            ...this.state,
            submit_loading: true,
            submit_log_id: log_id
        });

        console.log("Writing to db..", ret);

        Promise.all([ret.prm]).then(() => {
            console.log("Submit success!");
        }).catch(error => {
            console.log("Submit error", error);
            this.setState({
                ...this.state,
                submit_loading: false,
                submit_error: "Det har skjedd en feil. Prøv igjen eller kontakt support"
            });
        });
    };

    render(){
        // Stamp data
        let stamped_out = true;
        let stamp_string = "";
        let last_action = null;
        if (this.props.last_stamp){
            stamped_out = this.props.last_stamp.out;
            stamp_string = moment(this.props.last_stamp.ts).format('DD.MM.YYYY HH:mm');

            if (stamped_out){
                last_action = <span className='lastActionOut'>Stemplet ut</span>
            }else{
                last_action = <span className='lastActionIn'>Stemplet inn</span>
            }

        }else{
            stamp_string = "Fant ingen tidligere stemplinger.";
        }

        // Create stamp button
        let btn = null;
        if (this.state.submit_loading){
            btn = <RaisedButton backgroundColor={stamped_out ? '#4CAF50' : '#F44336'} icon={<CircularProgress size={24} thickness={3} color='white'/>}/>
        }else if (stamped_out){
            btn = <RaisedButton label='Stemple inn' backgroundColor='#4CAF50' onClick={() => {this.stamp(false)}}/>;
        }else{
            btn = <RaisedButton label='Stemple ut' backgroundColor='#F44336' onClick={() => {this.stamp(true)}}/>
        }

        const emp = this.props.employee;

        return(
            <Paper className='stampFormPaper'>
                <div className='stampFormName'>{emp.name}</div>
                <div>
                    <div className='lastStampText'>
                        <div>Forrige handling: {last_action}</div>
                        <div>{stamp_string}</div>
                    </div>
                    <div className='stampButton'>
                        {btn}
                    </div>
                    <div className='clear'/>
                </div>
            </Paper>
        );
    }
}