import React from 'react';

import Paper from "material-ui/Paper";
import moment from "moment";
import RaisedButton from "material-ui/RaisedButton";

export default class ExampleModule extends React.Component{
    constructor(props){
        super(props);

        const last_moment = moment();
        last_moment.subtract(24 * Math.random(), 'hours');

        this.state = {
            stampedOut: props.stamped_out,
            last_ts: last_moment.unix()
        }
    }

    stamp = stampedOut => {
        this.setState(pendingState => {
            return {
                ...pendingState,
                stampedOut: stampedOut,
                last_ts: moment().unix()
            }
        });
    };

    render(){
        // Stamp data
        const stamped_out = this.state.stampedOut;
        const stamp_string = moment.unix(this.state.last_ts).format('DD.MM.YYYY HH:mm');

        let last_action = null;
        if (stamped_out){
            last_action = <span className='lastActionOut'>Stemplet ut</span>
        }else{
            last_action = <span className='lastActionIn'>Stemplet inn</span>
        }


        // Create stamp button
        let btn = null;
        if (stamped_out){
            btn = <RaisedButton label='Stemple inn' backgroundColor='#4CAF50' onClick={() => {this.stamp(false)}}/>;
        }else{
            btn = <RaisedButton label='Stemple ut' backgroundColor='#F44336' onClick={() => {this.stamp(true)}}/>
        }

        return(
            <Paper className='stampFormPaper' style={{width: 'calc(100% - 2rem)', margin: '0.5rem 1rem'}}>
                <div className='stampFormName'>{this.props.name}</div>
                <div>
                    <div className='lastStampText'>
                        <div>Forrige handling: {last_action}</div>
                        <div>{stamp_string}</div>
                    </div>
                    <div className='stampButton'>
                        {btn}
                    </div>
                    <div className='clear'/>
                </div>
            </Paper>
        );
    }
}