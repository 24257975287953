import React from 'react';

import {Redirect, withRouter} from 'react-router-dom';
import {auth} from "../firebaseConfig";

import './NavigationContainer.css';

import moment from 'moment';

import LoginModule from '../LoginModule/LoginModule';

import AppBar from 'material-ui/AppBar';
import FlatButton from 'material-ui/FlatButton';
import IconButton from 'material-ui/IconButton';
import Popover from 'material-ui/Popover';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import Paper from 'material-ui/Paper';
import Dialog from 'material-ui/Dialog';
import withWidth from 'material-ui/utils/withWidth';

import SettingsIcon from 'material-ui-icons/Settings';
import PersonIcon from 'material-ui-icons/Person';
import BusinessIcon from 'material-ui-icons/Business';

import AccountCircleIcon from 'material-ui-icons/AccountCircle';
import EjectIcon from 'material-ui-icons/Eject';
import HelpIcon from 'material-ui-icons/Help';


class NavigationContainer extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            redirect: {
                frontpage: false,
                login: false,
                support: false,
                venues: false,
                account: false
            },
            login_open: false
        }
    }

    componentWillReceiveProps(nextProps){
        if (!!nextProps.forceLoginOpenValue){
            this.setState({
                ...this.state,
                login_open: true,
                new_user: true
            });

            nextProps.forceLoginOpenFinished();
        }

        if (nextProps.logged_in && !this.props.logged_in && this.props.location.pathname === '/'){
            this.redirectStart('venues');
            this.setState({
                ...this.state,
                login_open: false
            });
        }
    };

    redirectDone = (page) => {
        const new_state = Object.assign({}, this.state);

        new_state.redirect[page] = false;
        this.setState(new_state);
    };

    redirectStart = (page) => {
        const new_state = Object.assign({}, this.state);

        new_state.redirect[page] = true;
        this.setState(new_state);
    };

    handleLoginDialogClose = () => {
        this.setState({
            ...this.state,
            login_open: false
        });
    };

    handleLoginDialogOpen = () => {
        this.setState({
            ...this.state,
            login_open: true,
            new_user: false
        });
    };

    render(){
        console.log(this.props.width);
        return(
            <div>
                <AppBar
                    style={{backgroundColor: '#3F51B5'}}
                    title={this.props.width > 2 ? <span style={{cursor: 'pointer'}}>Personalliste.cloud</span> : null}
                    onTitleClick={() => {
                        if (!this.props.logged_in){
                            this.redirectStart('frontpage');
                        }else{
                            this.redirectStart('venues');
                        }}}
                    iconElementLeft={<img src='/images/logo_ring.svg' className='navigationContainerLogo' alt='Logo' style={{cursor: 'pointer'}} onClick={() => {this.redirectStart('frontpage');}}/>}
                    iconElementRight={this.props.logged_in ? <UserMenu logoutRedirect={this.props.logoutRedirect}
                                                                       redirectDone={this.redirectDone}
                                                                       userInfo={this.props.userInfo}
                                                                       width={this.props.width}
                                                                       redirectStart={this.redirectStart}/> : <FlatButton
                                                                            label={this.props.employeeAccount ? 'Logg inn som administrator' : 'Logg inn'}
                                                                            icon={<SettingsIcon/>}
                                                                            onClick={this.handleLoginDialogOpen}
                                                            />}
                />
                {(this.props.fsUser && this.props.logged_in && (this.props.fsUser.trialExpiredNotice || this.props.fsUser.disabledNotice || this.props.fsUser.unsubEffectiveNotice || this.props.fsUser.unsubImminentNotice)) ? <SubscriptionWarning fsUser={this.props.fsUser} redirectStart={this.redirectStart}/> : null}
                {this.state.redirect.frontpage ? <NavigationRedirect to='/' redirectDone={this.redirectDone} stateId='frontpage'/> : null}
                {this.state.redirect.support ? <NavigationRedirect to='/support' redirectDone={this.redirectDone} stateId='support'/> : null}
                {this.state.redirect.account ? <NavigationRedirect to='/konto' redirectDone={this.redirectDone} stateId='account'/> : null}
                {this.state.redirect.login ? <NavigationRedirect to='/login' redirectDone={this.redirectDone} stateId='login'/> : null}
                {this.state.redirect.venues ? <NavigationRedirect to='/virksomheter' redirectDone={this.redirectDone} stateId='venues'/> : null}
                <Dialog
                    open={this.state.login_open}
                    onRequestClose={this.handleLoginDialogClose}
                    keepMounted
                    contentStyle={{
                        maxWidth: '400px',
                        width: '100%',
                        maxHeight: '100%'
                    }}
                    autoScrollBodyContent={true}
                    repositionOnUpdate={false}
                    style={{paddingTop: this.props.width === 1 ? '0' : '110px'}}
                >
                    <LoginModule
                        new_user={this.state.new_user}
                        logged_in={this.props.logged_in}
                    />
                </Dialog>
                {/*{*/}
                {/*    !this.state.login_open ? <div style={{display: 'none'}}><LoginModule/></div> : null*/}
                {/*}*/}
            </div>
        );

    }
}

export default withRouter(withWidth()(NavigationContainer));


class UserMenu extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            open: false,
            redirect: null
        }
    }

    componentWillReceiveProps(nextProps){
        if (!nextProps.venues){return}

        // Create venues object
        const new_vens = nextProps.venues.slice();
        const vdict = {};

        if (!new_vens){return;}

        for (let ven of new_vens){
            vdict[ven.venue_id] = ven;
        }

        this.setState({
            ...this.state,
            venuesDict: vdict
        });
    }

    handleClick(event){
        event.preventDefault();

        this.setState({
            open: true,
            anchorEl: event.currentTarget
        });
    }

    handleRequestClose(){
        this.setState({
            open: false
        });
    }

    handleLogout = () => {
        console.log("This function is run");
        console.log(this.props);
        this.props.logoutRedirect();
    };

    render(){
        // color: white;
        // line-height: 36;
        // position: relative;
        // padding-left: 8px;
        // padding-right: 16px;
        // vertical-align: middle;
        // letter-spacing: 0px;
        // text-transform: uppercase;
        // font-weight: 500;
        // font-size: 14px;

        const labelStyle = {
            color: 'white',
            fontWeight: '500',
            fontSize: '14px'
        };

        const buttonStyle = {
            color: 'white',
            fontWeight: '500',
            fontSize: '14px',
            marginLeft: '1rem'
        };

        const buttonStyleMobile = {
            marginTop: '-4px',
            color: 'white'
        };

        const iconStyleMobile = {
            color: 'white'
        };

        let buttons;
        if (this.props.width === 1){
            buttons = (
                <React.Fragment>
                    <IconButton onClick={() => {this.props.redirectStart('venues')}}
                                style={buttonStyleMobile}
                                iconStyle={iconStyleMobile}
                                className={'navigationWhiteRipple'}
                    ><BusinessIcon/></IconButton>
                    <IconButton onClick={event => {this.handleClick(event)}}
                                style={buttonStyleMobile}
                                iconStyle={iconStyleMobile}
                                className={'navigationWhiteRipple'}
                    ><PersonIcon/></IconButton>
                </React.Fragment>
            );
        } else {
            buttons = (
                <React.Fragment>
                    <FlatButton label={'Virksomheter'}
                                style={buttonStyle}
                                labelStyle={labelStyle}
                                className={'navigationWhiteRipple'}
                                onClick={() => {this.props.redirectStart('venues')}}
                                icon={<BusinessIcon/>}
                    />
                    <FlatButton label={this.props.userInfo.displayName ? this.props.userInfo.displayName : 'Profil'}
                                style={buttonStyle}
                                labelStyle={labelStyle}
                                className={'navigationWhiteRipple'}
                                onClick={event => {this.handleClick(event)}}
                                icon={<PersonIcon/>}
                    />
                </React.Fragment>
            )
        }

        return(
            <div className='navButtonWrapper'>
                { buttons }
                <Popover
                    open={this.state.open}
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                    targetOrigin={{horizontal: 'right', vertical: 'top'}}
                    onRequestClose={() => {this.handleRequestClose()}}
                >
                    <Menu>
                        <MenuItem primaryText='Min Konto' rightIcon={<AccountCircleIcon/>} onClick={() => {this.props.redirectStart('account');}}/>
                        <MenuItem primaryText='Support' rightIcon={<HelpIcon/>} onClick={() => {this.props.redirectStart('support');}}/>
                        <MenuItem primaryText='Logg ut' rightIcon={<EjectIcon/>} onClick={() => {auth.signOut().then(() => {this.handleLogout()})}}/>
                    </Menu>
                </Popover>
            </div>);
    }
}

class SubscriptionWarning extends React.Component{
    render(){
        let msg = null;

        // For trial expiration
        if (this.props.fsUser.trialExpiredNotice){
            msg = (
                <p>Gratisperioden har utløpt. For å fortsette å bruke personalliste.cloud må du legge inn en betalingsløsning. {this.props.employeeAccount ? 'Logg inn i kontrollpanelet for å løse problemet.' : 'Trykk på denne meldingen for å legge inn en betalingsløsning.'}</p>
            );
        }

        // For missing payments
        if (this.props.fsUser.disabledNotice){
            msg = (
                <p>Vi kunne ikke belaste kortet ditt. Inn- og utstempling er sperret inntil du legger inn en fungerende betalingsløsning. {this.props.employeeAccount ? 'logg inn i kontrollpanelet for å løse problemet' : 'trykk på denne meldingen for å legge inn en ny betalingsløsning.'}</p>
            );
        }

        if (this.props.fsUser.unsubImminentNotice){
            msg = (
                <p>Du har kansellert abonnementet, men du kan fortsette å bruke systemet frem til slutten av betalingsperioden. ({moment.unix(this.props.fsUser.paid_until).format('DD.MM.YYYY HH:mm')})</p>
            );
        }

        if (this.props.fsUser.unsubEffectiveNotice){
            msg = (
                <p>Du har kansellert abonnementet. For å fortsette å gjenaktivere systemet, ${this.props.employeeAccount ? 'logg inn i kontrollpanelet' : 'trykk på denne meldingen og legg inn en betalingsløsning.'}</p>
            );
        }

        return(
            <Paper className='subscriptionWarningPaper' style={{backgroundColor: '#FFCDD2'}} onClick={() => {
                if (!this.props.employeeAccount){
                    this.props.redirectStart('account');
                }}
            }>
                {msg}
            </Paper>
        );
    }
}

class NavigationRedirect extends React.Component{
    componentDidMount(){
        this.props.redirectDone(this.props.stateId);
    }

    render(){
        return <Redirect to={this.props.to}/>
    }
}