import React from 'react';
import './PageMargins.css';
import withWidth from 'material-ui/utils/withWidth';

import Card from 'material-ui/Card';

class PageMargins extends React.Component{
    render(){
        const styles = {};
        if (this.props.noPadding){
            styles.padding = '0';
            styles.overflow = 'hidden';
        }

        return(
            <div className={this.props.width > 1 ? 'pageFull pageFullBackground' : 'pageFull'}>
                <Card className={'pageMargins'} style={styles}>
                    {this.props.children}
                </Card>
            </div>);
    }
}

export default withWidth()(PageMargins);