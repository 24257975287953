import React from 'react';

import Paper from 'material-ui/Paper';
import FlatButton from 'material-ui/FlatButton';

export default class CookieBanner extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            view: false,
            cookies_ok: false
        }
    }

    componentDidMount(){
        // Check for previous acceptances

        const re = new RegExp('cookie_policy_accepted=([^;]+)');
        const value = re.exec(document.cookie);
        const res = (value != null) ? unescape(value[1]) : null;

        if (!res){
            this.setState({
                ...this.state,
                view: true,
                cookie_ok: false
            });
        }else{

        }
    }

    handleCookieAcceptance = () => {
        this.setState({
            ...this.state,
            cookies_ok: true,
            view: false
        });

        const exdate = new Date();
        exdate.setDate(exdate.getDate() + 365);
        document.cookie = "cookie_policy_accepted=yes; expires="+exdate.toUTCString();
    };

    render(){
        return(
            <div>
                {
                    this.state.view ? <div style={{
                        position: 'fixed',
                        right: 0,
                        bottom: 0,
                        margin: '0 1rem 1rem 0',
                        maxWidth: '20rem'
                    }}>
                        <Paper zDepth={3} style={{padding: '1rem'}}>
                            <span>Personalliste.cloud bruker informasjonskapsler til anonym statistikkføring.</span>
                            <div style={{display: 'inline-block', float: 'right'}}>
                                <FlatButton
                                    label='Den er grei!'
                                    onClick={this.handleCookieAcceptance}
                                />
                            </div>
                            <div style={{clear: 'both'}}/>
                        </Paper>
                    </div> : null
                }
            </div>
        );
    }
}