import React from 'react';
import {firestore, functions} from "../firebaseConfig";

import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';
import CircularProgress from 'material-ui/CircularProgress';
import TextField from 'material-ui/TextField';
import ErrorIcon from 'material-ui-icons/Error';
import DoneIcon from 'material-ui-icons/Done';
import Divider from 'material-ui/Divider';
import Paper from 'material-ui/Paper';
import Snackbar from 'material-ui/Snackbar';

import CloseIcon from 'material-ui-icons/Close';
import AttachMoneyIcon from 'material-ui-icons/AttachMoney';
import QueryBuilderIcon from 'material-ui-icons/QueryBuilder';
import ReceiptIcon from 'material-ui-icons/Receipt';

import {List, ListItem} from 'material-ui/List';
import Avatar from 'material-ui/Avatar';

import './AccountPage.css';

import PageMargins from '../../presentational/PageMargins';
import InvoiceModule from './InvoiceModule'
import PaymentModule from "./PaymentModule";
import CreditCardIcon from "material-ui-icons/CreditCard";

export default class AccountPage extends React.Component{
    constructor(props){
        super(props);

        const stripeLoaded = window.stripeIsLoaded;

        this.state = {
            cancelOpen: false,
            loadingOpen: false,
            loadingHeader: "",
            loadingDone: false,
            loadingMessage: "",
            loadingSuccess: false,
            paymentInfoServerValue: {},
            paymentInfoDraft: {},
            snackbar_message: "",
            showUpdatePayment: false,
            paymentDialog: null,
            stripeLoaded: stripeLoaded
        };
    }

    componentWillMount(){
        this.props.onEnter("accountpage");
    }

    componentDidMount(){
        this.attemptListenerCreation();

        if (
            !this.state.stripeLoaded &&
            window.stripeIsLoaded
        ){
            this.setState(pendingState => {
                return {
                    ...pendingState,
                    stripeIsLoaded: true
                }
            });
        } else if (!this.state.stripeLoaded){
            window.stripeIsLoadedCallback = () => {
                this.setState(pendingState => {
                    return {
                        ...pendingState,
                        stripeIsLoaded: true
                    }
                });
            }
        }

        if (!window.loadingStripe && !this.state.stripeIsLoaded){
            this.loadStripe();
        }
    }

    loadStripe = () => {
        window.loadingStripe = true;

        new Promise(function (resolve, reject) {
            let s;
            s = document.createElement('script');
            s.src = 'https://js.stripe.com/v3/';
            s.onload = resolve;
            s.onerror = reject;
            document.head.appendChild(s);
        }).then(() => {
            console.log('Stripe is loaded!');
            this.setState(pendingState => {
                return {
                    ...pendingState,
                    stripeIsLoaded: true
                }
            });
        }).catch(err => {
            console.log('Could not load stripe!', err);
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.fsUser &&
            (!prevProps.fsUser || (prevProps.fsUser.uid !== this.props.fsUser.uid))
        ){
            this.attemptListenerCreation();
        }
    }

    attemptListenerCreation = () => {
        if (!this.props.fsUser || !this.props.fsUser.uid){return;}
        const uid = this.props.fsUser.uid;
        console.log(uid);

        this.customPaymentInfoListener = firestore.doc(`private_data/${uid}/account_settings/custom_payment_info`).onSnapshot(snap => {
            const data = snap.data();
            if (data){
                this.setState(pendingState => {
                    return {
                        ...pendingState,
                        paymentInfoServerValue: data
                    }
                });
            }
        });
    };

    componentWillUnmount(){
        if (this.customPaymentInfoListener){this.customPaymentInfoListener()}
    }

    componentWillReceiveProps(nextProps){
        if (!!this.paymentInfoDraft){
            return;
        }

        if (!nextProps.venues || nextProps.venues.length === 0){
            return;
        }

        this.paymentInfoDraft = {
            name: nextProps.venues[0].venue_name,
            addr: nextProps.venues[0].venue_addr,
            zip: nextProps.venues[0].venue_zip,
            city: nextProps.venues[0].venue_city,
            country: "Norge"
        };
    }

    handleCancelOpen = () => {
        this.setState({
            ...this.state,
            cancelOpen: true
        });
    };

    handleCancelClose = () => {
        this.setState({
            ...this.state,
            cancelOpen: false
        });
    };

    handleCancelConfirm = () => {
        this.setState(pendingState => {
            return {
                ...pendingState,
                cancelOpen: false,
                loadingOpen: true,
                loadingMessage: 'Dette kan ta noen sekunder...',
                loadingHeader: 'Avslutter abonnement',
                loadingDone: false
            }
        });

        const cancelSubscription = functions.httpsCallable('cancelSubscription');
        cancelSubscription().then(res => {
            this.setState(pendingState => {
                return {
                    ...pendingState,
                    loadingDone: true,
                    loadingSuccess: res.data.status === 'success',
                    loadingMessage: res.data.status === 'success' ? 'Abonnementet er avsluttet.' : 'Det har oppstått en uventet feil. Kontakt support.'
                }
            });

            if (res.data.status === 'success'){
                this.props.fireAnalyticsEvent('ga_unsub');
            }
        });
    };

    onOpened = (test) => {
        console.log("onOpened", test)
    };

    onClosed = (test) => {
        console.log("onClosed, test");
    };

    handleLoadingClose = () => {
        this.setState({
            ...this.state,
            loadingOpen: false
        });
    };

    attachInvoiceInfoChange = field => event => {
        const newVal = event.target.value;
        this.setState(pendingState => {
            return {
                ...pendingState,
                paymentInfoDraft: {
                    ...pendingState.paymentInfoDraft,
                    [field]: newVal
                },
                paymentInfoChanged: true
            }
        })
    };

    getCurrentPaymentInfo = () => {
        // Account info
        const paymentInfo = {};
        ['name', 'addr', 'zip', 'city', 'country'].forEach(field => {
            if (this.state.paymentInfoDraft.hasOwnProperty(field)){
                paymentInfo[field] = this.state.paymentInfoDraft[field];
            } else if (this.state.paymentInfoServerValue.hasOwnProperty(field)){
                paymentInfo[field] = this.state.paymentInfoServerValue[field];
            } else {
                paymentInfo[field] = '';
            }
        });

        return paymentInfo;
    };

    handlePaymentInfoSaved = () => {
        console.log(this.props);
        if (!this.props.fsUser || !this.props.fsUser.uid){return};
        const uid = this.props.fsUser.uid;

        this.setState({
            ...this.state,
            paymentInfoChanged: false
        });

        const currentInfo = this.getCurrentPaymentInfo();
        firestore.doc(`private_data/${uid}/account_settings/custom_payment_info`).set(currentInfo).then(() => {
            console.log('custom_payment_info updated');
        }).catch(err => {
            console.error(err)
        });
    };

    snackMessage = (message) => {
        this.setState({
            ...this.state,
            snackbar_message: message
        })
    };

    handleSnackbarClose = () => {
        this.setState({
            ...this.state,
            snackbar_message: ""
        })
    };

    showUpdatePayment = () => {
        this.setState(pendingState => {
            return {
                ...pendingState,
                showUpdatePayment: true
            }
        })
    };

    showPaymentDialog = dialogData => {
        this.setState(pendingState => {
            return {
                ...pendingState,
                paymentDialog: dialogData
            }
        })
    };

    closePaymentDialog = () => {
        this.setState(pendingState => {
            return {
                ...pendingState,
                paymentDialog: null
            }
        })
    };

    hideUpdatePayment = () => {
        this.setState(pendingState => {
            return {
                ...pendingState,
                showUpdatePayment: false
            }
        })
    };

    render(){
        if (!this.props.fsUser){
            return null;
        }

        const paymentInfo = this.getCurrentPaymentInfo();

        let paymentModule;
        if (!this.state.stripeIsLoaded){
            paymentModule = null;
        } else if (!this.props.fsUser.stripeSubscriptionId || this.state.showUpdatePayment){
            paymentModule = (
                <PaymentModule
                    firestore={this.props.firestore}
                    fsUser={this.props.fsUser}
                    showPaymentDialog={this.showPaymentDialog}
                    hideUpdatePayment={this.hideUpdatePayment}
                />
            )
        } else {
            paymentModule = (
                <div>
                    <p>Du har et aktivt abonnement.</p>
                    <RaisedButton label={'Bytt betalingskort'} icon={<CreditCardIcon/>} onClick={this.showUpdatePayment}/>
                </div>
            );
        }

        return(
            <PageMargins>
                <h1>Din konto</h1>
                <p>Bruk av personliste.cloud etter at den 30 dager lange gratisperioden har løpt ut koster 79,-/mnd. Du kan legge inn kortinformasjon ved å trykke på den grønne knappen nedenfor. Beløpet betales forskuddsvis hver måned, og det er ingen bindingstid.</p>

                <List>
                    <ListItem disabled={true} leftAvatar={<Avatar icon={<AttachMoneyIcon/>}/>}>Kun 79,- i måneden. Ingen oppstartskostnader</ListItem>
                    <ListItem disabled={true} leftAvatar={<Avatar icon={<QueryBuilderIcon/>}/>}>Ingen bindingstid. Du kan avslutte abonnementet når som helst.</ListItem>
                    <ListItem disabled={true} leftAvatar={<Avatar icon={<ReceiptIcon/>}/>}>Fullstending fakturaoversikt</ListItem>
                </List>

                {!this.props.fsUser.trialExpired ? <p>Kortet vil ikke belastes før gratisperioden har løpt ut.</p> : null}

                <br/>
                <div>
                    <div className='accountPageHalfPageOnSmallScreens' style={{verticalAlign: 'top'}}>
                        {this.props.venues ? <Paper className='accountPageAddTextFieldPadding accountPagePaperMargins'>
                            <h3>Fakturainformasjon: </h3>
                            <p style={{paddingLeft: '1rem'}}>Informasjonen nedenfor vil stå på kvitteringene.</p>
                            <Divider/>
                            <TextField
                                hintText="Navn"
                                value={paymentInfo.name}
                                underlineShow={false}
                                onChange={this.attachInvoiceInfoChange('name')}
                            />
                            <Divider/>
                            <TextField
                                hintText="Adresse"
                                value={paymentInfo.addr}
                                underlineShow={false}
                                onChange={this.attachInvoiceInfoChange('addr')}
                            /><Divider/>
                            <TextField
                                hintText="Postnummer"
                                type="number"
                                value={paymentInfo.zip}
                                underlineShow={false}
                                onChange={this.attachInvoiceInfoChange('zip')}
                            /><Divider/>
                            <TextField
                                hintText="Poststed"
                                value={paymentInfo.city}
                                underlineShow={false}
                                onChange={this.attachInvoiceInfoChange('city')}
                            />
                            <Divider/>
                            <TextField
                                hintText="Land"
                                value={paymentInfo.country}
                                underlineShow={false}
                                onChange={this.attachInvoiceInfoChange('country')}
                            />
                            {this.state.paymentInfoChanged ? <div style={{marginLeft: '0'}}><Divider/><div className='accountPageSavePaymentInfoWrapper'><RaisedButton label="Largre endringer" backgroundColor='#4CAF50' onClick={this.handlePaymentInfoSaved}/></div></div> : null}
                        </Paper> : null}
                    </div>
                    <div className='accountPageHalfPageOnSmallScreens' style={{verticalAlign: 'bottom'}}
                    >
                        <Paper className='accountPagePaperMargins'>
                            <h3>Abonnement og betaling</h3>
                            <div className='accountPageButtonsModuleWrapper'>
                                {paymentModule}
                                <div className='accountPageButtonWrapper'>
                                    {!this.props.fsUser.is_cancelled && this.props.fsUser.stripeSubscriptionId ? <RaisedButton label='Avslutt abonnement' icon={<CloseIcon/>} backgroundColor='#F44336' onClick={this.handleCancelOpen}/> : null}
                                </div>
                                <br/>
                            </div>
                        </Paper>
                    </div>
                </div>
                <br/><br/>
                <div className='accountPageHalfPageOnSmallScreens'>
                    <InvoiceModule
                        fsUser={this.props.fsUser}
                    />
                </div>
                <Dialog
                    open={this.state.cancelOpen}
                    title='Avslutt abonnement?'
                    actions={[<RaisedButton label='Avslutt' backgroundColor='#F44336' onClick={this.handleCancelConfirm}/>, <FlatButton label='Avbryt' onClick={this.handleCancelClose}/>]}
                    onRequestClose={this.handleCancelClose}
                />
                <Dialog
                    open={this.state.loadingOpen}
                    title={this.state.loadingHeader}
                    actions={[<FlatButton label='Lukk' onClick={this.handleLoadingClose} disabled={!this.state.loadingDone}/>]}
                >
                    {!this.state.loadingDone ? <div className='accountPageLoadingBodyWrapper'>
                        <CircularProgress size={60}/>
                        <p>
                            {this.state.loadingMessage}
                        </p>
                    </div> : <div className='accountPageLoadingBodyWrapperDone'>
                        <div className={this.state.loadingSuccess ? 'accountPageLoadingDoneStatusIconSuccess' : 'accountPageLoadingDoneStatusIconFailure'}>
                            {this.state.loadingSuccess ? <DoneIcon/> : <ErrorIcon/>}
                        </div>
                        <p>{this.state.loadingMessage}</p>
                    </div>}
                </Dialog>
                {
                    this.state.paymentDialog ? (
                        <Dialog
                            open
                            title={this.state.paymentDialog.title || ''}
                            actions={[<FlatButton label='OK' onClick={this.closePaymentDialog}/>]}
                            onRequestClose={this.closePaymentDialog}>
                            { this.state.paymentDialog.message || '' }
                        </Dialog>
                    ) : null
                }
                <Snackbar
                    open={this.state.snackbar_message.length > 0}
                    message={this.state.snackbar_message}
                    autoHideDuration={4000}
                    onRequestClose={this.handleSnackbarClose}
                />
            </PageMargins>
        )
    }
}