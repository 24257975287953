import React from 'react';
import DatePicker from "material-ui/DatePicker";
import TimePicker from "material-ui/TimePicker";
import {RadioButton, RadioButtonGroup} from "material-ui/RadioButton";
import CircularProgress from "material-ui/CircularProgress";
import FindInPageIcon from "material-ui-icons/FindInPage";
import ReportProblemIcon from 'material-ui-icons/ReportProblem';
import CloudDownload from 'material-ui-icons/CloudDownload'
import RaisedButton from "material-ui/RaisedButton";
import {grey200} from 'material-ui/styles/colors'
import {firestore} from "../firebaseConfig";
import moment from "moment";
import './Timesheet.css'
const Tooltip = React.lazy(() => import('./Tooltip'));

export default class Timesheet extends React.Component{
    state = {
        dateRange: {
            from: null,
            to: null
        },
        forgottenStrategy: 'doNothing',
        forgottenEndTime: {h: 18, m: 0},
        submit_loading: false,
        timeSheet: null
    };

    setDate = toFrom => (na, date) => {
        this.setState(pendingState => {
            return {
                ...pendingState,
                dateRange: {
                    ...pendingState.dateRange,
                    [toFrom]: date
                }
            }
        });
    };

    setForgottenStrategy = event => {
        const newVal = event.target.value;
        console.log(newVal);

        this.setState(pendingState => {
            return {
                ...pendingState,
                forgottenStrategy: newVal
            }
        })
    };

    setStopTime = (na,date) => {
        this.setState(pendingState => {
            return {
                ...pendingState,
                forgottenEndTime: {
                    h: date.getHours(),
                    m: date.getMinutes()
                }
            }
        })
    };

    pendingSubmit = false;
    handleSubmit = () => {
        if (!firestore){return;}
        if (!this.props.employees){console.warn('Missing employees!'); return;}
        if (!this.props.venue_id){console.warn('Missing venue id'); return;}
        if (!this.props.fsUser){console.warn('Missing fsUser'); return;}
        if (!this.state.dateRange.from || !this.state.dateRange.to){ return;}
        if (this.state.dateRange.from > this.state.dateRange.to){return;} // To time before from time

        this.pendingSubmit = true;

        const employeeIds = this.props.employees.map(employee => employee.employee_id);
        const uid = this.props.fsUser.uid;
        const venueId = this.props.venue_id;

        const fromTime = moment(this.state.dateRange.from);
        fromTime.startOf('day');
        const fromTs = fromTime.valueOf();

        const toTime = moment(this.state.dateRange.to);
        toTime.endOf('day');
        const toTs = toTime.valueOf();

        this.setState(pendingState => {
            return {
                ...pendingState,
                submit_loading: true
            }
        }, () => {
            const prms = employeeIds.map(employeeId => {
                return firestore.collection(`private_data/${uid}/venues/${venueId}/employees/${employeeId}/stamp_log`)
                    .where('ts', '>=', fromTs)
                    .where('ts', '<=', toTs)
                    .orderBy('ts')
                    .get();
            });

            Promise.all(prms).then(employeeSnaps => {
                const outliersPromises = [];
                const stampsPerEmployee = {};
                const hideProviders = [];
                employeeSnaps.forEach((docs, index) => {
                    // Get all stamps for this employee - the data will already be sorted
                    const stamps = [];
                    const employeeId = employeeIds[index];
                    docs.forEach(doc => {stamps.push(doc.data());});

                    if (stamps.length === 0){
                        if (this.props.employees[index].deleted){   // No stamp data + deleted -> hide in results
                            hideProviders.push(employeeId);
                        }
                        return;
                    }

                    // If first is out -> unshift an in at midnight, start of range
                    if (stamps[0].out){
                        stamps.unshift({out: false, ts: fromTs, fallback: true, beforeRange: true});
                        // const prm = firestore.collection(`private_data/${uid}/venues/${venueId}/employees/${employeeId}/stamp_log`)
                        //     .where('ts', '<', stamps[0].ts)
                        //     .orderBy('ts', 'desc')
                        //     .limit(1)
                        //     .get().then(snaps => {
                        //         if (snaps.size === 1){
                        //             const outlierData = snaps.docs[0].data();
                        //             stamps.unshift({out: false, ts: outlierData.ts, fallback: false, outsideRange: true})
                        //         } else {
                        //             stamps.unshift({out: false, ts: fromTs, fallback: true, outsideRange: true}); // Fallback solution
                        //         }
                        //     });
                        // outliersPromises.push(prm);
                    }

                    // If last is in -> push an out at end of range
                    const lastInRange = stamps[stamps.length -1];
                    if (!lastInRange.out){
                        const prm = firestore.collection(`private_data/${uid}/venues/${venueId}/employees/${employeeId}/stamp_log`)
                            .where('ts', '>', lastInRange.ts)
                            .orderBy('ts', 'asc')
                            .limit(2)
                            .get().then(snaps => {
                                if (snaps.size < 2){
                                    stamps.push({out: true, ts: toTs, fallback: true, outsideRange: true});
                                } else {
                                    stamps.push({out: true, ts: snaps.docs[0].data().ts, fallback: false, outsideRange: true});
                                    stamps.push({out: false, ts: snaps.docs[1].data().ts, fallback: false, outsideRange: true});
                                }
                            });
                        outliersPromises.push(prm);
                    }

                    stampsPerEmployee[employeeId] = stamps;
                });

                const hoursPerEmployeePerDay = {};
                Promise.all(outliersPromises).then(() => {
                    console.log(stampsPerEmployee);

                    // Now we generate the ranges
                    employeeIds.forEach(employeeId => {
                        const ranges = [];
                        const averageDays = {};

                        // If no data -> no ranges to generate
                        if (!stampsPerEmployee.hasOwnProperty(employeeId)){return;}

                        stampsPerEmployee[employeeId].forEach((stamp, index) => {
                            const thisStamp = stamp;
                            const nextStamp = stampsPerEmployee[employeeId][index + 1];

                            if (thisStamp.out){return;}
                            if (!nextStamp){return;}

                            const range = {from: moment(thisStamp.ts), to: moment(nextStamp.ts)};

                            // Detect forgotten
                            const thirdStamp = stampsPerEmployee[employeeId][index + 2];

                            if (
                                this.state.forgottenStrategy !== 'doNothing' && // a strat is enabled
                                thirdStamp &&  // There's data to confirm a forgotten stamp
                                thirdStamp.ts < nextStamp.ts + 60000 && // A new stamp-in comes right after
                                (!range.from.isSame(range.to, 'day') || range.from.isSameOrBefore(fromTime))  // Stamped in overnight
                            ){
                                if (this.state.forgottenStrategy === 'setStop'){
                                    const forceEnd = range.from.clone();
                                    forceEnd.hour(this.state.forgottenEndTime.h);
                                    forceEnd.minute(this.state.forgottenEndTime.m);
                                    range.to = forceEnd;
                                    range.autoCorrect = true;
                                    if (range.from.isSameOrBefore(fromTime)){range.disable = true;}
                                } else if (this.state.forgottenStrategy === 'smartAverage'){
                                    range.averageMe = true;
                                    range.autoCorrect = true;
                                    if (range.from.isSameOrBefore(fromTime)){range.disable = true;}
                                }
                            } else if (  // We have a valid range, and the strategy is smartAverage, so include this range in the average
                                this.state.forgottenStrategy === 'smartAverage' &&
                                range.from.isSame(range.to, 'day')
                            ){
                                const key = range.from.format('YYYYMMDD');

                                let newTotal = averageDays.hasOwnProperty(key) ? averageDays[key] : 0;
                                newTotal += (range.to.unix() - range.from.unix()) / 60;
                                averageDays[key] = newTotal;
                            }

                            ranges.push(range);
                        });

                        if (this.state.forgottenStrategy === 'smartAverage'){
                            let total = 0;
                            let days = 0;

                            Object.keys(averageDays).forEach(key => {
                                total += averageDays[key];
                                days += 1;
                            });

                            const avg = days > 0 ? total / days : 0;  // No valid avg. data? -> 0

                            // Now change the ranges
                            ranges.forEach(range => {
                                if (!range.averageMe){return;}

                                const newTo = range.from.clone();
                                newTo.add(avg, 'minutes');

                                range.to = newTo;
                            });
                        }

                        ranges.forEach((range, index) => {
                            while (range.from.isSameOrBefore(range.to)){
                                const YYYYMMDD = range.from.format('YYYYMMDD');
                                let toWithinDay = range.to;

                                if (range.disable){break;}

                                if (!range.to.isSame(range.from, 'day')){
                                    toWithinDay = range.from.clone();
                                    toWithinDay.endOf('day');
                                }

                                if (!hoursPerEmployeePerDay.hasOwnProperty(YYYYMMDD)){
                                    hoursPerEmployeePerDay[YYYYMMDD] = {};
                                }

                                if (!hoursPerEmployeePerDay[YYYYMMDD].hasOwnProperty(employeeId)){
                                    hoursPerEmployeePerDay[YYYYMMDD][employeeId] = {minutes: 0, autoCorrect: !!range.autoCorrect};
                                }

                                hoursPerEmployeePerDay[YYYYMMDD][employeeId].minutes += (toWithinDay.unix() - range.from.unix()) / 60;

                                range.from.add(1, 'days');
                                range.from.startOf('day');
                            }
                        });
                    });

                    // console.log(hoursPerEmployeePerDay);
                    const currentDate = fromTime.clone();
                    const res = [];
                    while (currentDate.isSameOrBefore(toTime)){
                        const YYYYMMDD = currentDate.format('YYYYMMDD');
                        const date = {
                            dateString: currentDate.format('DD.MM.YYYY')
                        };

                        employeeIds.forEach(employeeId => {
                            if (
                                hoursPerEmployeePerDay.hasOwnProperty(YYYYMMDD) &&
                                hoursPerEmployeePerDay[YYYYMMDD].hasOwnProperty(employeeId)
                            ) {
                                date[employeeId] = hoursPerEmployeePerDay[YYYYMMDD][employeeId];
                            } else {
                                date[employeeId] = {minutes: 0, autoCorrect: false};
                            }
                        });

                        res.push(date);
                        currentDate.add(1, 'days');
                    }

                    this.setState(pendingState => {
                        return {
                            ...pendingState,
                            submit_loading: false,
                            timeSheet: res,
                            hideProviders: hideProviders
                        }
                    });
                });
            });
        });
    };

    generateCSV = () => {
        if (!this.state.timeSheet){return;}

        const employeeIds = this.props.employees.map(employee => employee.employee_id).filter(id => !this.state.hideProviders.includes(id));
        const employees = this.props.employees.filter(employee => employeeIds.includes(employee.employee_id));

        const firstRow = employees.map(employee => employee.name);
        firstRow.unshift('Dato');

        const dataRows = [];
        this.state.timeSheet.forEach(row => {
            const dataCols = employeeIds.map(employeeId => {
                return row[employeeId].minutes / 60;
            });
            dataCols.unshift(row.dateString);

            dataRows.push(dataCols);
        });

        dataRows.unshift(firstRow);

        const csvContent = "data:text/csv;charset=utf-8," + dataRows.map(r => r.join(';')).join('\n');
        const encodedUri = encodeURI(csvContent);

        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "timeliste.csv");
        document.body.appendChild(link); // Required for FF
        link.click();
    };

    render(){
        let results = null;

        if (this.state.timeSheet){
            const employeeIds = this.props.employees.map(employee => employee.employee_id).filter(id => !this.state.hideProviders.includes(id));
            const employees = this.props.employees.filter(employee => employeeIds.includes(employee.employee_id));

            results = (
                <React.Fragment>
                    <table className={'timesheet-result-table'}>
                        <thead>
                        <tr>
                            <td>Dato</td>
                            { employees.map(employee => <td key={'header' + employee.employee_id}>{ employee.name }</td>)}
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.timeSheet.map(row => {
                                return(
                                    <tr key={row.dateString}>
                                        <td>{ row.dateString }</td>
                                        { employeeIds.map(employeeId => {
                                            const t = row[employeeId].minutes;
                                            const h = Math.floor(t / 60);
                                            const m = Math.floor(t - (h * 60));
                                            let extraProps = {};
                                            if (row[employeeId].autoCorrect){
                                                extraProps = {
                                                    'data-for': 'react-tooltip',
                                                    'data-tip': 'Glemt utstempling automatisk rettet'
                                                }
                                            }
                                            const display = t === 0 && m === 0 ? '-' : `${h.toString()}t ${m > 0 ? m.toString() + 'm' : ''}`;
                                            return(
                                                <td key={row.dateString + employeeId}>
                                                    <div {...extraProps}>
                                                    { display }{ row[employeeId].autoCorrect ? <ReportProblemIcon color={'rgba(0,0,0,0.87)'} style={{width: '1.1rem', height: '1.1rem', paddingLeft: '0.5rem', verticalAlign: 'middle'}}/> : null }
                                                    </div>
                                                </td>);
                                        })}
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </React.Fragment>
            );
        }

        const yesterday = moment();
        yesterday.subtract(1, 'days');

        let fromMaxDate = yesterday.toDate();
        if (this.state.dateRange.to){
           fromMaxDate = this.state.dateRange.to;
        }

        return(
            <React.Fragment>
                <h1>Timeliste</h1>
                <p>På denne siden kan du generere, vise og eksportere timelister basert på når de ansatte har stemplet inn og ut.</p>
                <div>
                    <h3>Velg tidsrom</h3>
                    <div className='datePickerWrapper'>
                        <DatePicker
                            autoOk={true}
                            cancelLabel='Avbryt'
                            onChange={this.setDate('from')}
                            hintText='Fra dato'
                            maxDate={fromMaxDate}
                        />
                    </div>
                    <div className='datePickerWrapper'>
                        <DatePicker
                            autoOk={true}
                            cancelLabel='Avbryt'
                            onChange={this.setDate('to')}
                            hintText='Til dato'
                            minDate={this.state.dateRange.from ? this.state.dateRange.from : undefined}
                            maxDate={yesterday.toDate()}
                        />
                    </div>
                </div>
                <div>
                    <h3>Glemte utstemplinger</h3>
                    <p>Dersom den ansatte har en innstemplet periode som varer over natten, etterfulgt av en rask utstempling og ny innstempling har vedkommende antakelig glemt å stemple ut dagen før. Vi kan forsøke å rette dette automatisk. Dagene som er rettet markeres i timelisten.</p>
                    <RadioButtonGroup onChange={this.setForgottenStrategy} valueSelected={this.state.forgottenStrategy} name={''}>
                        <RadioButton
                            value={'doNothing'}
                            label={'Ikke gjør noe - jeg håndterer dette manuelt'}
                        />
                        <RadioButton
                            value={'setStop'}
                            label={'Avslutt dagen automatisk ved et klokkeslett'}
                        />
                        <RadioButton
                            value={'smartAverage'}
                            label={'Gjennomsnitt - gjør dagen like lang som gjennomsnittet av de øvrige dagene'}
                        />
                    </RadioButtonGroup>
                    {
                        this.state.forgottenStrategy === 'setStop' ? (
                            <div>
                                <span>Avslutt dagen kl.</span>
                                <TimePicker
                                    autoOk={true}
                                    cancelLabel='Avbryt'
                                    onChange={this.setStopTime}
                                    hintText='Avslutt dag kl.'
                                    format={'24hr'}
                                    style={{display: 'inline-block', marginLeft: '1rem'}}
                                    value={new Date(2000,1,1, this.state.forgottenEndTime.h, this.state.forgottenEndTime.m)}
                                    textFieldStyle={{width: '4rem'}}
                                />
                            </div>
                        ) : null
                    }
                </div>
                <div>
                    <h3>Generer timeliste</h3>
                    <RaisedButton
                        onClick={this.handleSubmit}
                        label='Generer rapport'
                        backgroundColor='#4CAF50'
                        icon={this.state.submit_loading ? <CircularProgress size={24} thickness={3} color='white'/> : <FindInPageIcon/>}
                    />
                    { this.state.timeSheet ? (
                        <RaisedButton
                            onClick={this.generateCSV}
                            label='Eksporter til Excel'
                            backgroundColor={grey200}
                            icon={this.state.excel_loading ? <CircularProgress size={24} thickness={3} color='white'/> : <CloudDownload/>}
                            className={'timesheet-excel'}
                        />
                    ) : null }
                </div>
                <div>
                    <h3>Resultat</h3>
                    {results}
                </div>
                <React.Suspense fallback={null}>
                    <Tooltip res={this.state.timeSheet}/>
                </React.Suspense>
            </React.Fragment>
        )
    }
}