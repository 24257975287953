import React from 'react';
import './Overview.css';

import StampModule from '../StampModule/StampModule'
import EmployeeList from '../EmployeeList/EmployeeList';
import AuditPage from '../AuditPage/AuditPage';
import EmployeeLinkModule from './EmployeeLinkModule';

import PageMargins from '../../presentational/PageMargins';

// Material ui
import {Tabs, Tab} from 'material-ui/Tabs';
import RaisedButton from 'material-ui/RaisedButton';
import SnackBar from 'material-ui/Snackbar';
import CircularProgress from 'material-ui/CircularProgress';
import Toggle from 'material-ui/Toggle';
import withWidth from 'material-ui/utils/withWidth';

import SaveIcon from 'material-ui-icons/Save';
import InputIcon from 'material-ui-icons/Input';
import PeopleIcon from 'material-ui-icons/People';
import PrintIcon from 'material-ui-icons/Print';
import ListIcon from 'material-ui-icons/List';


import Timesheet from "../Timesheet/Timesheet";

class OverviewPage extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            tab_id: 'a',
            validation_message: "",
            save_success_message: "",
            save_submit_loading: false,
            view_deleted: false
        };

        this.employees_draft = [];
        this.employees_draft_valid = null;
    }

    componentWillMount(){
        this.props.onEnter("overview");
    }

    handleTabSwitch = (tab_id) => {
        this.setState({
            ...this.state,
            tab_id: tab_id
        });

        this.props.onEnter(`overview/${tab_id}`);
    };

    componentDidMount(){
        this.employees_listener = this.props.listenForEmployees(this.props.match.params.venue_id);
    }


    componentWillUnmount(){
        // Remove listener on unmount
        if (this.employees_listener){
            this.employees_listener();
        }
    }

    handleEmployeeListUpdate = (new_data, is_valid) => {
        this.employees_draft = new_data;
        this.employees_draft_valid = is_valid;
    };

    // Employee list update
    handleEmployeeSubmit = () => {
        const new_employee_data = JSON.parse(JSON.stringify(this.employees_draft));
        const old_employee_data = JSON.parse(JSON.stringify(this.props.employees));
        const changes = {};
        const adds = [];
        const old_data = {};

        // Validate
        if (this.employees_draft_valid){
            this.setState({
                ...this.state,
                validation_message: this.employees_draft_valid
            });
        }

        // Create id-defined object of old employee data
        for (let emp of old_employee_data){
            old_data[emp.employee_id] = Object.assign({}, emp);
            delete old_data.employee_id;
        }

        // console.log("-------------------");
        // console.log("Starting comparison");
        // console.log("employees_draft", this.employees_draft);
        // console.log("old_employee_data", old_employee_data);
        // console.log("old_data", old_data);
        // console.log("new_employee_data", new_employee_data);
        // console.log("-------------------");

        // Look for changes
        for (let emp of new_employee_data){
            // console.log("Checking for changes...", emp.employee_id);
            // console.log("New data", emp);
            // console.log("Old data", old_data[emp.employee_id]);
            if (emp.employee_id){
                // look for changes
                for (let key of Object.keys(emp)){
                    if (emp[key] !== old_data[emp.employee_id][key]){
                        changes[emp.employee_id] = emp;
                        delete changes[emp.employee_id].employee_id;

                        break;
                    }
                }
            }else{
                adds.push(emp);
            }
        }

        console.log("Changes: ", changes);
        console.log("Adds: ", adds);

        const promises = [];
        // Push changes to server
        for (let emp_id of Object.keys(changes)){
            promises.push(this.props.applyEmployeeChanges(this.props.match.params.venue_id, emp_id, changes[emp_id]));
        }

        // Push adds to server
        promises.push(this.props.addEmployees(this.props.match.params.venue_id, adds));

        this.setState({
            ...this.state,
            save_submit_loading: true
        });

        Promise.all(promises).then(() => {
            this.setState({
                ...this.state,
                save_submit_loading: false,
                save_success_message: "Endringene er lagret"
            });
        }).catch(err => {
            console.log(err);

            this.setState({
                ...this.state,
                save_submit_loading: false,
                save_success_message: "Noe gikk galt. Prøv igjen eller kontakt support."
            });
        });

    };


    handleSnackbarClose = () => {
        this.setState({
            ...this.state,
            validation_message: ""
        });
    };

    handleSaveSuccessClose = () => {
        this.setState({
            ...this.state,
            save_success_message: ""
        });
    };

    handleViewDeletedToggle = (event) => {
        this.setState({
            ...this.state,
            view_deleted: !this.state.view_deleted
        });
    };

    render(){
        let save_icon = null;

        if(this.state.save_submit_loading){
            save_icon = <CircularProgress size={24} thickness={3} color='white'/>;
        }else{
            save_icon = <SaveIcon/>;
        }

        return(
            <PageMargins noPadding>
                <Tabs
                    value={this.state.tab_id}
                    onChange={this.handleTabSwitch}
                    tabItemContainerStyle={{backgroundColor: 'rgb(63, 81, 181)'}}
                    contentContainerClassName={'overview-tab'}
                >
                    <Tab label={this.props.width === 1 ? <InputIcon color={'white'}/> : "Stemple inn/ut"} value='a'>
                        <EmployeeLinkModule
                            setEmployeeLinkActive={this.props.setEmployeeLinkActive}
                            venues={this.props.venues}
                            venue_id={this.props.match.params.venue_id}
                        />
                        <StampModule employees={this.props.employees}
                                     last_stamp={this.props.last_stamp}
                                     addStamp={this.props.addStamp}
                                     venue_id={this.props.match.params.venue_id}
                                     listenForLastStamp={this.props.listenForLastStamp}
                                     fsUser={this.props.fsUser}
                                     openDisabledSnack={this.props.openDisabledSnack}
                        />
                    </Tab>
                    <Tab label={this.props.width === 1 ? <PeopleIcon color={'white'}/> : "Ansatte"} value='b'>
                        <div className='viewDeletedToggleWrapper'>
                            <Toggle label='Vis slettede ansatte' toggled={this.state.view_deleted} onToggle={this.handleViewDeletedToggle}/>
                        </div>
                        <div className='clear'/>
                        <EmployeeList onUpdate={this.handleEmployeeListUpdate}
                                      employees={this.props.employees}
                                      viewDeleted={this.state.view_deleted}
                        />
                        <div style={{textAlign: 'center'}}>
                            <RaisedButton label='Lagre endringer' backgroundColor='#4CAF50' onClick={this.handleEmployeeSubmit} icon={save_icon}/>
                            <p>Merk: Endringer på ansatte vil ikke få effekt tilbake i tid.</p>
                        </div>
                    </Tab>
                    <Tab label={this.props.width === 1 ? <PrintIcon color={'white'}/> : "Kontroll/Skriv ut"} value='c'>
                        <AuditPage
                            exportRequest={this.props.exportRequest}
                            venue_id={this.props.match.params.venue_id}
                            employees={this.props.employees}
                            venues={this.props.venues}
                        />
                    </Tab>
                    <Tab label={this.props.width === 1 ? <ListIcon color={'white'}/> : "Timeliste"} value={'d'}>
                        <Timesheet
                            venue_id={this.props.match.params.venue_id}
                            employees={this.props.employees}
                            fsUser={this.props.fsUser}
                        />
                    </Tab>
                </Tabs>
                <SnackBar
                    open={this.state.validation_message.length !== 0}
                    message={this.state.validation_message}
                    autoHideDuration={4000}
                    onRequestClose={this.handleSnackbarClose}
                />
                <SnackBar
                    open={this.state.save_success_message.length !== 0}
                    message={this.state.save_success_message}
                    autoHideDuration={4000}
                    onRequestClose={this.handleSaveSuccessClose}
                />
            </PageMargins>
        );
    }
}

export default withWidth()(OverviewPage);