import React from 'react';

import '../../presentational/PageMargins';


import './Frontpage.css';
import PageMargins from "../../presentational/PageMargins";

import {List, ListItem} from 'material-ui/List';
import Avatar from 'material-ui/Avatar';
import RaisedButton from 'material-ui/RaisedButton';

// icons
import AttachMoneyIcon from 'material-ui-icons/AttachMoney';
import QueryBuilderIcon from 'material-ui-icons/QueryBuilder';
import ListIcon from 'material-ui-icons/List';
import AlarmOnIcon from 'material-ui-icons/AlarmOn';
import SupervisorAccountIcon from 'material-ui-icons/SupervisorAccount';
import AccountBalanceIcon from 'material-ui-icons/AccountBalance';

import Paper from 'material-ui/Card';
import ExampleModule from "./ExampleModule";

// import CloudIcon from 'material-ui-icons/Cloud';
// import ResultsTable from "../AuditPage/ResultsTable";

export default class Frontpage extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            loginDialogOpen: false
        };
    }

    componentWillMount(){
        this.props.onEnter("frontpage");
    }

    render(){
        const avatar_color = '#4CAF50';

        return(
            <PageMargins showBackgroundImage={true}>
                <div style={{position: 'relative'}}>
                    <Paper circle={true} zDepth={3} className='frontpageCloudCircle'>
                        <div>
                            <img src='/images/logo.svg' className='frontpageLogoCircle' alt='Logo' />
                        </div>
                    </Paper>
                    <div className='frontPageTitle'>
                        <h1>Personalliste på nett</h1>
                        <p>Med personalliste.cloud kan dine ansatte skrive seg inn og ut ved å trykke på én knapp. De kan bruke sine egne smarttelefoner, eller dere kan ha siden oppe på en PC i lokalet. Ved kontroll kan listen skrives ut. Du kan legge til så mange virksomheter og ansatte du ønsker.</p>
                    </div>
                    <div>
                        <div className={'frontpage-g6'}>
                            <h2 className='frontpage-sector-heading'>
                                Eksempel - prøv selv!
                                <span className={'frontpage-example-arrow'}><img alt={'pil'} src={'/images/arrow.svg'}/></span>
                            </h2>
                            <ExampleModule stamped_out={true} name={'Ola Nordmann'}/>
                            <ExampleModule stamped_out={false} name={'Kari Nordmann'}/>
                        </div>
                        <div className={'frontpage-g6 frontpage-signup-wrapper'}>
                            <h3 className={'frontpage-sector-heading frontpage-adline'}>Ubegrenset antall ansatte!<br/>Kun 79,-/mnd</h3>
                            <RaisedButton label={'Prøv gratis i 30 dager!'}
                                          backgroundColor='#3F51B5'
                                          labelColor='#ffffff'
                                          onClick={this.props.forceLoginOpen}/>
                            <p>Du kan prøve systemet gratis i 30 dager, uten å legge inn betalingskort!</p>
                        </div>
                    </div>
                    <div className={'frontpage-topMargin'}>
                        <div className='frontPageListWrapper'>
                            <List style={{padding: '0'}}>
                                <ListItem disabled={true} leftAvatar={<Avatar backgroundColor={avatar_color} icon={<AccountBalanceIcon/>}/>}>Forskriftsmessig personallistesystem</ListItem>
                                <ListItem disabled={true} leftAvatar={<Avatar backgroundColor={avatar_color} icon={<ListIcon/>}/>}>Timelister med automatisk retting av glemte utstemplinger. Eksport til Excel.</ListItem>
                                <ListItem disabled={true} leftAvatar={<Avatar backgroundColor={avatar_color} icon={<AlarmOnIcon/>}/>}>Prøv gratis og uforpliktende i 30 dager.</ListItem>
                            </List>
                        </div>
                        <div className='frontPageListWrapper'>
                            <List style={{padding: '0'}}>
                                <ListItem disabled={true} leftAvatar={<Avatar backgroundColor={avatar_color} icon={<AttachMoneyIcon/>}/>}>Kun 79,- i måneden. Ingen oppstartskostnader. Ubegrenset antall ansatte.</ListItem>
                                <ListItem disabled={true} leftAvatar={<Avatar backgroundColor={avatar_color} icon={<QueryBuilderIcon/>}/>}>Ingen bindingstid. Du kan avslutte abonnementet når som helst.</ListItem>
                                <ListItem disabled={true} leftAvatar={<Avatar backgroundColor={avatar_color} icon={<SupervisorAccountIcon/>}/>}>Svært brukervennlig</ListItem>
                            </List>
                        </div>
                    </div>
                    <div className={'frontpage-topMargin'}>
                        <h2 className='frontpage-sector-heading'>Følgende bransjer er pålagt å føre personalliste</h2>
                        <div>
                            <div className='frontpage-sector'>
                                <h3>Serveringssteder</h3>
                                <p>For eksempel café, restaurant, pub, gatekjøkken og catering.</p>
                            </div>
                            <div className='frontpage-sector'>
                                <h3>Frisering og skjønnhetspleie</h3>
                                <p>For eksempel manikyr, pedikyr, kroppspleie, ansiktsbehandling, vippeextensions, farging av vipper eller bryn, microblading, piercing og tannbleking.</p>
                            </div>
                            <div className='frontpage-sector'>
                                <h3>Bilverksted og bilpleie</h3>
                                <p>Bilverksteder, bilvask og billakkering.</p>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div className='frontPageButtonWrapper frontpage-topMargin'>
                        <RaisedButton label={'Prøv gratis i 30 dager!'}
                                      backgroundColor='#3F51B5'
                                      labelColor='#ffffff'
                                      onClick={this.props.forceLoginOpen}/>
                        <p className={'frontpage-signup-caption'}>Du kan prøve systemet gratis i 30 dager, uten å legge inn betalingskort!</p>
                    </div>
                    <div className={'frontpage-topMargin'}/>
                </div>
            </PageMargins>
        );
    }
}