import React from 'react';

import {CopyToClipboard} from 'react-copy-to-clipboard';
import './EmployeeLinkModule.css';

import {Redirect} from 'react-router-dom';

import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';
import Toggle from 'material-ui/Toggle';
import Dialog from 'material-ui/Dialog';

import OpenInBrowserIcon from 'material-ui-icons/OpenInBrowser';
import ContentCopyIcon from 'material-ui-icons/ContentCopy';


export default class EmployeeLinkModule extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            linkActiveToggle_loading: false,
            linkActive: props.linkActive || false,
            linkSubmitLoading: false,
            employeePageAlertOpen: false,
            employeePageRedirectNow: false
        }
    }

    componentWillReceiveProps(nextProps){
        const vens = {};

        if (nextProps.venues){
            for (let ven of nextProps.venues){
                vens[ven.venue_id] = ven;
            }
        }

        this.setState({
            ...this.state,
            linkActive: !!vens[nextProps.venue_id].employeeLinkActive
        });
    }

    handleOpenPage = () => {
        let link = null;
        const vens = {};
        if (this.props.venues){
            for (let ven of this.props.venues){
                vens[ven.venue_id] = ven;
            }
        }

        if (Object.keys(vens).length > 0 && vens[this.props.venue_id].employeeLinkActive && vens[this.props.venue_id].employee_login_id){
            link = vens[this.props.venue_id].employee_login_id;
        }

        if (!this.state.linkActive || !link){
            console.log("Interrupted!", this.state.linkActive, link);
            console.log(Object.keys(vens).length, vens[this.props.venue_id].employeeLinkActive, vens[this.props.venue_id].employee_login_id);
            return;
        }

        // const newWindow = window.open();
        // newWindow.opener = null;
        // newWindow.location = link;

        this.setState({
            ...this.state,
            employeePageRedirectNow: '/ansattlink/' + link
        });
    };

    handleOpenPageDone = () => {
        this.setState({
            ...this.state,
            employeePageRedirectNow: false
        })
    };

    handleLinkActiveToggle = () => {
        this.props.setEmployeeLinkActive(this.props.venue_id, !this.state.linkActive).then(() => {
            console.log("Successfully set linkActive");
            this.setState({
                ...this.state,
                linkSubmitLoading: false
            });
        }).catch(err => {
            console.log(err);
        });

        this.setState({
            ...this.state,
            linkActive: !this.state.linkActive,
            linkSubmitLoading: true
        });
    };

    handleEmployeePageAlertOpen = () => {
        this.setState({
            ...this.state,
            employeePageAlertOpen: true
        });
    };

    handleEmployeePageAlertClose = () => {
        this.setState({
            ...this.state,
            employeePageAlertOpen: false
        });
    };

    render(){
        const vens = {};

        if (this.props.venues){
            for (let ven of this.props.venues){
                vens[ven.venue_id] = ven;
            }
        }else{
            return null;
        }

        let link = null;
        if (Object.keys(vens).length > 0 && vens[this.props.venue_id].employeeLinkActive && vens[this.props.venue_id].employee_login_id){
            link = 'https://personalliste.cloud/ansattlink/'+vens[this.props.venue_id].employee_login_id;
        }

        let clip_copy = null;
        if (!(!this.state.linkActive || !link)){
            clip_copy = (
                <CopyToClipboard text={link}>
                    <RaisedButton label='Koper til utklippstavlen' icon={<ContentCopyIcon/>} backgroundColor='#B0BEC5' disabled={!this.state.linkActive || !link}/>
                </CopyToClipboard>
            );
        }else{
            clip_copy = <RaisedButton label='Koper til utklippstavlen' icon={<ContentCopyIcon/>} backgroundColor='#B0BEC5' disabled={!this.state.linkActive || !link}/>;
        }

        return(
            <div className='moduleWrapper'>
                <div className='moduleCard'>
                    <h3>Link for de ansatte</h3>
                    <p>Du kan dele denne linken med de ansatte så de kan stemple seg inn og ut, uten å være logget inn på dette kontrollpanelet.</p>
                    {this.props.venues && this.state.linkActive && vens[this.props.venue_id].pin ? <p>For å åpne ansattsiden, bruk pin-kode: <span style={{fontWeight: 'bold'}}>{vens[this.props.venue_id].pin}</span></p> : null}
                    {this.state.linkActive ? (<div className='linkBox'><div>{!!link ? link : 'Genererer link...'}</div></div>) : null}
                    <div className='linkWrapper'>
                        <div><RaisedButton label='Åpne ansattsiden' icon={<OpenInBrowserIcon/>} backgroundColor='#B0BEC5' disabled={!this.state.linkActive || !link} onClick={this.handleEmployeePageAlertOpen}/></div>
                        <div>{clip_copy}</div>
                        <div><Toggle label='Aktiver link for de ansatte' toggled={this.state.linkActive} onClick={this.handleLinkActiveToggle} disabled={this.state.linkSubmitLoading}/></div>
                    </div>
                </div>
                <Dialog
                    open={this.state.employeePageAlertOpen}
                    modal={false}
                    onRequestClose={this.handleEmployeePageAlertClose}
                    actions={[
                    <FlatButton label='Gå til ansattsiden' onClick={this.handleOpenPage}/>,
                    <FlatButton label='Avbryt' onClick={this.handleEmployeePageAlertClose}/>
                ]}
                >
                    <p>Ved å bytte til ansattsiden vil du bli logget ut av kontrollpanelet. For å logge inn på ansattsiden trenger man pin-kode: <span style={{fontWeight: 'bold'}}>{vens[this.props.venue_id].pin}</span></p>
                </Dialog>
                {this.state.employeePageRedirectNow ? <EmployeePageRedirect link={this.state.employeePageRedirectNow} employeeRedirectDone={this.handleOpenPageDone}/> : null}
            </div>
        );
    }
}


class EmployeePageRedirect extends React.Component{
    componentDidMount(){
        this.props.employeeRedirectDone();
    }

    render(){
        return <Redirect to={this.props.link}/>
    }
}