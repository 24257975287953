import React from 'react';

import './FooterModule.css';

import Paper from 'material-ui/Paper';
import Divider from 'material-ui/Divider';
import {NavLink} from 'react-router-dom';
import contactInfo from '../data/contactInfo';

export default class FooterModule extends React.Component{
    render(){
        return(
            <Paper style={{
                backgroundColor:'#1A237E',
                color: 'white'
            }}>
                <div className='footerModuleContent'>
                    <div className='footerModuleInfoBlock'>
                        <span>Personalliste.cloud</span>
                        <div className='footerModuleInfoBlockSpacer'/>
                        <Divider/>
                        <div className='footerModuleInfoBlockSpacer'/>
                        Tobias Lillestølen Software<br/>
                        Org.nr: 920 671 950<br/>
                        Søndre Gate 2<br/>
                        0550 Oslo<br/>
                        Norge<br/>
                    </div>
                    <div className='footerModuleInfoBlock'>
                        <span>Kontaktinformasjon:</span>
                        <div className='footerModuleInfoBlockSpacer'/>
                        <Divider/>
                        <div className='footerModuleInfoBlockSpacer'/>
                        E-post: <a href={'mailto:' + contactInfo.email}>{contactInfo.email}</a><br/>
                        Tlf: {contactInfo.phone}<br/>
                    </div>
                    <div className='footerModuleInfoBlock'>
                        <span>Vilkår og personvern</span>
                        <div className='footerModuleInfoBlockSpacer'/>
                        <Divider/>
                        <div className='footerModuleInfoBlockSpacer'/>
                        <NavLink to='/tos'>Vilkår for bruk</NavLink><br/>
                        <NavLink to='/personvern'>Personvern</NavLink><br/>
                    </div>
                </div>
                <br/>
            </Paper>
        );
    }
}