import React from 'react';

import PageMargins from '../../presentational/PageMargins';
import contactInfo from '../../data/contactInfo';

export default class SupportPage extends React.Component{
    componentWillMount(){
        this.props.onEnter('Support');
    }

    render(){
        return(
            <PageMargins>
                <h1>Support</h1>
                <p>
                    Er det noe som helst du lurer på kan du ta kontakt på:<br/><br/>
                    E-post: <a href={'mailto:' + contactInfo.email}>{contactInfo.email}</a><br/>
                    Telefon: {contactInfo.phone}
                </p>
            </PageMargins>
        );
    }
}