import React from 'react';

import './AuditPage.css';

// Material UI
import DatePicker from 'material-ui/DatePicker';
import RaisedButton from 'material-ui/RaisedButton';
import SnackBar from 'material-ui/Snackbar';
import CircularProgress from 'material-ui/CircularProgress';

import FindInPageIcon from 'material-ui-icons/FindInPage';
import PrintIcon from 'material-ui-icons/Print';

import ResultsTable from '../AuditPage/ResultsTable';

export default class AuditPage extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            min_date: null,
            max_date: null,
            submit_loading: false,
            snackbar_message: "",
            results: null,
            employee_versions: null,
            view_mode: 'raw'
        }
    }

    handleMinChange = (nothing,date) => {
        this.setState({
            ...this.state,
            min_date: date
        });
    };

    handleMaxChange = (nothing,date) => {
        this.setState({
            ...this.state,
            max_date: date
        });
    };

    handleSubmit = () => {
        console.log("Submit!");

        if (!this.state.min_date || !this.state.max_date){
            this.setState({
                ...this.state,
                snackbar_message: "Velg en fra- og en til-dato"
            });

            return;
        }

        const dt_from = new Date(this.state.min_date.getTime());
        const dt_to = new Date(this.state.max_date.getTime());

        dt_to.setDate(dt_to.getDate() + 1);

        const max_ts = dt_to.getTime();
        const min_ts = dt_from.getTime();

        if (max_ts < min_ts){
            this.setState({
                ...this.state,
                snackbar_message: "Til-dato må være senere enn fra-dato."
            });

            return;
        }

        const prm = this.props.exportRequest(this.props.venue_id, min_ts, max_ts);

        this.setState({
            ...this.state,
            submit_loading: true
        });

        Promise.all([prm]).then(results => {
            this.setState({
                ...this.state,
                submit_loading: false,
                results: results[0].stamps,
                employee_versions: results[0].employee_versions
            });
        }).catch(err => {
            console.log(err);
            this.setState({
                ...this.state,
                snackbar_message: "Det har oppstått en feil. Kontakt support. (Feilkode 1)",
                results: null
            });
        });
    };

    handlePrintRequest = () => {
        console.log("Print request");
        window.print();
    };

    handleSnackbarClose = () => {
        this.setState({
            ...this.state,
            snackbar_message: ""
        });
    };

    render(){
        const venueData = {
            name: '',
            nr: '',
            manager: ''
        };

        let employees = [];
        if (this.props.employees){
            this.props.employees.forEach(employee => {
                employees.push({name: employee.name, ssn: employee.ssn});
            });
        }

        if (this.state.results){
            Object.keys(this.state.results).forEach(employeeId => {
                this.state.results[employeeId].forEach(stamp => {
                    for (let employee of employees){
                        if (employee.ssn === stamp.ssn && employee.name === stamp.name){return;}
                    }

                    employees.push({ssn: stamp.ssn, name: stamp.name});
                });
            });
        }

        if (
            this.props.venues &&
            this.props.venue_id
        ){
            const venues = this.props.venues.filter(venue => venue.venue_id === this.props.venue_id);
            if (venues.length > 0){
                const venue = venues[0];
                venueData.name = venue.venue_name;
                venueData.nr = venue.venue_nr;
                venueData.manager = venue.venue_boss;
            }
        }

        return(
            <div>
                <h1>Kontroll/Skriv ut</h1>
                <p>Her kan man hente ut all registrert data mellom de to valgte datoene. Vær obs på at dette kan ta en liten stund om man velger et langt tidsrom, med mye data.</p>
                <div>
                    <div className='datePickerWrapper'>
                        <DatePicker
                            autoOk={true}
                            cancelLabel='Avbryt'
                            onChange={this.handleMinChange}
                            hintText='Fra dato'
                        />
                    </div>
                    <div className='datePickerWrapper'>
                        <DatePicker
                            autoOk={true}
                            cancelLabel='Avbryt'
                            onChange={this.handleMaxChange}
                            hintText='Til dato'
                        />
                    </div>
                </div>
                <div>
                    <div className='buttonWrapperAudit'>
                        <RaisedButton
                            onClick={this.handleSubmit}
                            label='Hent data'
                            backgroundColor='#4CAF50'
                            icon={this.state.submit_loading ? <CircularProgress size={24} thickness={3} color='white'/> : <FindInPageIcon/>}
                        />
                    </div>
                    <div className='buttonWrapperAudit'>
                        {this.state.results ? <RaisedButton
                            onClick={this.handlePrintRequest}
                            label='Skriv ut'
                            backgroundColor='#B0BEC5'
                            icon={<PrintIcon/>}
                        /> : null}
                    </div>
                </div>
                <div>
                    {this.state.results ? (
                        <React.Fragment>
                            <div className={'audit-page-venue-info'}>
                                <h3>Virksomheten</h3>
                                <table className={'audit-info-table'}>
                                    <tbody>
                                    <tr>
                                        <td>Virksomhetens navn</td>
                                        <td>{venueData.name}</td>
                                    </tr>
                                    <tr>
                                        <td>Organisasjonsnummer</td>
                                        <td>{venueData.nr}</td>
                                    </tr>
                                    <tr>
                                        <td>Navn på innehaver / daglig leder</td>
                                        <td>{venueData.manager}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className={'audit-page-venue-info'}>
                                <h3>Liste over ansatte</h3>
                                <table className={'audit-info-table'}>
                                    <thead>
                                    <tr>
                                        <td>Navn</td>
                                        <td>Personnummer / Kode</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        employees.map(employee => {
                                            return(
                                                <tr key={employee.ssn + employee.name}>
                                                    <td>{employee.name}</td>
                                                    <td>{employee.ssn}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                            <ResultsTable results={this.state.results}
                                          viewMode={this.state.view_mode}
                                          employees={this.props.employees}
                                          employee_versions={this.state.employee_versions}/>
                        </React.Fragment>
                    ) : null}
                </div>
                <SnackBar
                    open={this.state.snackbar_message.length > 0}
                    message={this.state.snackbar_message}
                    autoHideDuration={4000}
                    onRequestClose={this.handleSnackbarClose}
                />
            </div>
        );
    }
}