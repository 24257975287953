import React from 'react';
import {StripeProvider, Elements} from 'react-stripe-elements';
import {stripeKey} from "../../data/appConfig";
import CheckoutForm from "./CheckoutForm";

export default class PaymentModule extends React.Component{
    render(){
        return(
            <React.Fragment>
                <StripeProvider apiKey={stripeKey}>
                    <Elements
                        locale={'no'}
                        fonts={[{family: 'Roboto, sans-serif', cssSrc: 'https://fonts.googleapis.com/css?family=Roboto'}]}
                    >
                        <CheckoutForm fsUser={this.props.fsUser} showPaymentDialog={this.props.showPaymentDialog} hideUpdatePayment={this.props.hideUpdatePayment}/>
                    </Elements>
                </StripeProvider>
            </React.Fragment>
        )
    }
}